import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import Accordion from 'react-bootstrap/Accordion';
import AOS from "aos";
import { getfaq } from '../axioscall/user'
export default function FAQ() {
    React.useEffect(() => {
        AOS.init({
            once: true,
            offset: 0,
            duration: 300,
            easing: 'ease-in-quad',
            delay: 0,
        });
        getfaqlist()
    }, []);

    const [faq, setFaq] = React.useState([])

    const getfaqlist = async () => {
        var resp = await getfaq();
        console.log("faq", resp.data);
        if (resp.status) {
            setFaq(resp.data)
        }
    }


    return (
        <>
            <Header />
            <div className="fm_faq">
                <div className="container-fluid">
                    <img src={require("../assets/images/faq.png")} className="img-fluid" />
                    <h1 data-aos="fade-left">Have any Questions?</h1>
                    <div className="fm_have_doubt" data-aos="zoom-in">
                        <div className="fm_subscriber d-flex align-items-center">
                            <input type="text" placeholder="I have doubt" className="form-control" />
                            <button className="btn btn-warning">Search</button>
                        </div>
                    </div>
                    <div className="fm_faq_container">
                        <div className="fm_recently_asked_questions">
                            <p>Recently Asked Questions:</p>
                        </div>
                        <div className="fm_accordion">
                            <Accordion defaultActiveKey="0">
                                {faq.map((item, index) => (
                                    <><div className="d-flex w-100 justify-content-between mt-3">
                                        <p>{index + 1}.</p>
                                        <Accordion.Item eventKey={index} data-aos="fade-left">
                                            <Accordion.Header><img className="faq_pencil" src={require('../assets/images/pencil.png')}/>{item.question}?</Accordion.Header>
                                            <Accordion.Body>
                                            <div dangerouslySetInnerHTML={{__html: item.answer}}/>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </div>
                                    </>
                                ))}

                                {/* <div className="d-flex w-100 justify-content-between mt-3">
                    <p>2.</p>
                        <Accordion.Item eventKey="1" data-aos="fade-left">
                            <Accordion.Header><span></span>What is NFT?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        </div>
                        <div className="d-flex w-100 justify-content-between mt-3">
                            <p>3.</p>
                        <Accordion.Item eventKey="2" data-aos="fade-left">
                            
                            <Accordion.Header><span></span>What is NFT?</Accordion.Header>
                           
                            <Accordion.Body>
                                <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        </div>
                        <div className="d-flex w-100 justify-content-between mt-3">
                            <p>4.</p>
                        <Accordion.Item eventKey="3" data-aos="fade-left">
                            
                            <Accordion.Header><span></span>What is NFT?</Accordion.Header>
                            
                            <Accordion.Body>
                                <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        </div>
                        <div className="d-flex w-100 justify-content-between mt-3">
                            <p>5.</p>
                        <Accordion.Item eventKey="4" data-aos="fade-left">
                            
                            <Accordion.Header><span></span>What is NFT?</Accordion.Header>
                            
                            <Accordion.Body>
                                <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        </div>
                        <div className="d-flex w-100 justify-content-between mt-3">
                            <p>6.</p>
                        <Accordion.Item eventKey="5" data-aos="fade-left">
                            <Accordion.Header><span></span>What is NFT?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        </div> */}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}