import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import AOS from "aos";
import config from '../lib/config'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { getnftdetails } from "../axioscall/token";
import * as userctrl from '../axioscall/user'
import { useState } from "react";
export default function ProvenanceRecord(){
    const [nftdata,setNftdata]=useState([])
    const [cms,setCms]=useState({})
    React.useEffect(() => {
        getnftdetail()
        getcmss()
        AOS.init({
            once: true,
            offset: 0,
            duration: 300,
            easing: 'ease-in-quad',
            delay: 0,
        });
      }, []);


      const getcmss = async () => {
        console.log("nfsdn")

        var resp = await userctrl.getcms();
        console.log("cms", resp);
        if(resp.status){
       setCms({
        "provenancerecord": resp.data.filter(item=> item.slug=="provenancerecord")[0],
        "concatenatedhashstring" : resp.data.filter(item=> item.slug=="concatenatedhashstring")[0],
       })
        }
       
    }  
        const getnftdetail=async()=>{
        var resp=await getnftdetails();
        console.log("rsp",resp);
        if(resp.status){
            setNftdata(resp.data)
        }
      }
    return(
        <>
        <Header />
        <div className="fm_provenance_record">
        <img src={require("../assets/images/Record.png")} className="img-fluid" />
            <h1 className="fm_big_size" data-aos="fade-left">Provenance Record</h1>
            <div className="container-fluid">
            <p data-aos="fade-left">{ReactHtmlParser(cms?.provenancerecord?.answer)}</p>
            </div>
            <div className="container-fluid" data-aos="zoom-in">
                <div className="fm_important_notification">
                    <h1>Important Notification</h1>
                    <p>Each Freaky Feko token ID is assigned to an artwork image from the initial sequence with this formula:</p>
                </div>
            </div>
            <div className="container-fluid" data-aos="zoom-in">
                <div className="fm_important_notification">
                    <h1>Here's the relevant information:</h1>
                    <div className="table-responsive">
                    <table class="table table-borderless">
                    <tbody>
                        <tr>
                        <td style={{textAlign:"right",color:"#fff"}}>FINALIZED STARTING INDEX <span>&emsp;|</span></td>
                        <td style={{textAlign:"left",color:"#F9F871"}}>{nftdata.length}</td>
                        </tr>
                        <tr>
                        <td style={{textAlign:"right",color:"#fff"}}>TheFreakyStudios CONTRACT ADDRESS<span>&emsp;|</span></td>
                        <td style={{textAlign:"left",color:"#F9F871"}}>{config.Contractaddress}</td>
                        </tr>
                        <tr>
                        <td style={{textAlign:"right",color:"#fff"}}>FINAL PROOF HASH<span>&emsp;|</span></td>
                        <td style={{textAlign:"left",color:"#F9F871"}}>cc354b3fcacee8844dcc9861004da081a43412752752c0bf</td>
                        </tr>
                        {/* <tr>
                        <td style={{textAlign:"right",color:"#fff"}}>ARWEAVE PERMAWEB URIS<span>&emsp;|</span></td>
                        <td style={{textAlign:"left",color:"#F9F871"}}>List of Arweave URIs</td>
                        </tr> */}
                    </tbody>
                    </table>
                    </div>
                </div>
            </div>
            <div className="container-fluid" data-aos="zoom-in">
                <div className="fm_concated_hash_string">
                <h1>Concatenated Hash String</h1>
                <div className="fm_hash_string">
                    <p>{ReactHtmlParser(cms?.concatenatedhashstring?.answer)}</p>

                </div>
                </div>
            </div>
            <div className="fm_provenancne" data-aos="zoom-in">
                <div className="container-fluid">
                <h1>Provenance Record</h1>
                <p>The table below lists the original index, assigned BAYC Token ID, SHA256 Hash output and IPFS link of each Bored Ape image.</p>
                <ul className="d-flex align-items-center justify-content-center fm_table_record">
                    <li>INITIAL SEQUENCE INDEX</li>
                    <li>ASSIGNED BAYC TOKEN ID</li>
                    <li>SHA-256 HASH </li>
                    <li>IPFS HASH</li>
                </ul>
                <div className="table-responsive">
                <table className="table table-borderless">
                <tbody>

                    {nftdata && nftdata.map((item,index)=>(
                        <>
                        <tr>
                        <td style={{color:"#fff"}}>{index}<span>&emsp;|</span></td>
                        <td style={{color:"#fff"}}>{item.TokenId}<span>&emsp;|</span></td>
                        <td style={{color:"#fff"}}>{item.Transactionhash}<span>&emsp;|</span></td>
                        <a href={item.imagePath} target="balnk"><td style={{color:"#F9F871"}}>{item.Tokenuri}</td></a>
                    </tr></>
                    ))}
                    {/* <tr>
                        <td style={{color:"#fff"}}>0<span>&emsp;|</span></td>
                        <td style={{color:"#fff"}}>2247<span>&emsp;|</span></td>
                        <td style={{color:"#fff"}}>c92b0386542d8beb8fb167aed611e73c5f30250a083f54edf78653946a8a6913<span>&emsp;|</span></td>
                        <td style={{color:"#F9F871"}}>QmdUXVtRxKhdVhjPXCUxZGyXNHn5e2oS6pt8enP</td>
                    </tr>
                    <tr>
                        <td style={{color:"#fff"}}>1<span style={{color:"#fff"}}>&emsp;|</span></td>
                        <td style={{color:"#fff"}}>2247<span>&emsp;|</span></td>
                        <td style={{color:"#fff"}}>c92b0386542d8beb8fb167aed611e73c5f30250a083f54edf78653946a8a6913<span>&emsp;|</span></td>
                        <td style={{color:"#F9F871"}}>QmdUXVtRxKhdVhjPXCUxZGyXNHn5e2oS6pt8enP</td>
                    </tr>
                    <tr>
                        <td style={{color:"#fff"}}>2<span>&emsp;|</span></td>
                        <td style={{color:"#fff"}}>2247<span>&emsp;|</span></td>
                        <td style={{color:"#fff"}}>c92b0386542d8beb8fb167aed611e73c5f30250a083f54edf78653946a8a6913<span>&emsp;|</span></td>
                        <td style={{color:"#F9F871"}}>QmdUXVtRxKhdVhjPXCUxZGyXNHn5e2oS6pt8enP</td>
                    </tr>
                    <tr>
                        <td style={{color:"#fff"}}>3<span>&emsp;|</span></td>
                        <td style={{color:"#fff"}}>2247<span>&emsp;|</span></td>
                        <td style={{color:"#fff"}}>c92b0386542d8beb8fb167aed611e73c5f30250a083f54edf78653946a8a6913<span>&emsp;|</span></td>
                        <td style={{color:"#F9F871"}}>QmdUXVtRxKhdVhjPXCUxZGyXNHn5e2oS6pt8enP</td>
                    </tr>
                    <tr>
                        <td style={{color:"#fff"}}>4<span>&emsp;|</span></td>
                        <td style={{color:"#fff"}}>2247<span>&emsp;|</span></td>
                        <td style={{color:"#fff"}}>c92b0386542d8beb8fb167aed611e73c5f30250a083f54edf78653946a8a6913<span>&emsp;|</span></td>
                        <td style={{color:"#F9F871"}}>QmdUXVtRxKhdVhjPXCUxZGyXNHn5e2oS6pt8enP</td>
                    </tr> */}
                </tbody>
                </table>  
                </div>
                </div>  
            </div>
        </div>
        <Footer />
        </>
    )
}