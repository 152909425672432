import React, { useEffect, useState } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { Link } from "react-router-dom";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AOS from "aos";
import Web3 from "web3"
import * as userctrl from '../axioscall/user'
import { getimages } from "../axioscall/user";
import config from '../lib/config'
// import { validate } from "../../../backend/Model/front_models/tokenSchema";
export default function CommunityMembership() {
    const [filters,setFilters]=useState({"Unconventional_Icon" : {}})
    const[cms,setCms]=useState({})
    const initailvalue={
        email : "",
      walletaddress :"",
      nftid :""
    }
    useEffect(()=>{
        getimage()
        getcmss()
    },[])


    const getcmss = async () => {
        console.log("nfsdn")

        var resp = await userctrl.getcms();
        console.log("cms", resp);
        if(resp.status){
       setCms({
        "aboutus": resp.data.filter(item=> item.slug=="aboutus")[0],
        "theunconventionalicon" : resp.data.filter(item=> item.slug=="theunconventionalicon")[0],
        "thefreakyfekocommunity": resp.data.filter(item=> item.slug=="thefreakyfekocommunity")[0],
        "whybecomeamember": resp.data.filter(item=> item.slug=="whybecomeamember")[0],
        "howtojoinfreakyfekocommunity" :  resp.data.filter(item=> item.slug=="howtojoinfreakyfekocommunity")[0],
        "thefutureofthefreakyfeko" : resp.data.filter(item=> item.slug=="thefutureofthefreakyfeko")[0],
        "digitalplatforms" : resp.data.filter(item=> item.slug=="digitalplatforms")[0],
        "votingrights" : resp.data.filter(item=> item.slug=="votingrights")[0],
        "discountsandrewards" : resp.data.filter(item=> item.slug=="discountsandrewards")[0],
        "stakingrewards" : resp.data.filter(item=> item.slug=="stakingrewards")[0],
       })
        }
       
    }
    const getimage=async()=>{
        var resp=await getimages();
        console.log("imagedata",resp.data,resp);
        var Unconventional_Icon=resp.data.filter(character => character.content === 'Unconventional_Icon')
       
    console.log(Unconventional_Icon,"Unconventional_Icon");
       setFilters({"Unconventional_Icon" : Unconventional_Icon[0]})
       console.log(filters,"filters")
        // setImage(resp.data)
    }
    const [memberinfo, setMemberinfo] = useState({
        email : "",
      walletaddress :"",
      nftid :""
    })
    const [modelmsg,setModelmsg]=useState('')
    const [error, setError] = useState({
        email : "",
      walletaddress :"",
      nftid :""
    })
    console.log("enndadaddasd",memberinfo?.email == "" )
    React.useEffect(() => {
        AOS.init({
            delay: 0,

        });
    }, []);
    const options = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        smartSpeed: 1200,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    }
    const onchangehandler = (e) => {
        const { id, value } = e.target;
        let data = { id: value }
        setMemberinfo({ ...memberinfo, [id]: value })
        console.log("memberinfo", memberinfo)
    }

    const onsubmithandler = async () => {
        await Validation(memberinfo);
        if (Object.keys(error).length === 0) {
            var resp = await userctrl.addcommunity(memberinfo)
            if (resp.status) {
                setMemberinfo({})
                setError({})
                setModelmsg(resp.msg)
                console.log("community a save pannitte ", resp.msg)
            }
            else {
                setModelmsg(resp.msg)
                console.log("community ", resp.msg)
            }
        }
        else{
            setModelmsg("Please Fill All The Inputs/Correctly")
        }
    }
    function Validation(member) {
        var err = {}
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(member.email)) {
            console.log("mailcheck")
            err.email = "email not valid"
            // setError({...error,"email":"email not valid"})
        }
        console.log("hello dai vada", Web3.utils.isAddress(member.walletaddress))
        if (!Web3.utils.isAddress(member.walletaddress)) {
            console.log("add check")
            err.walletaddress = "walletaddress not valid"
            // setError({...error,"address":"walletaddress not valid"})
        }
        if (!/[0-9]/.test(member.nftid)) {
            console.log("id check")
            err.nftid = "nft not valid"
            // setError({...error,"nftid":"nft not valid"})

        }
        setError(err)
    }

    console.log("sdefgefs",cms);
    console.log("eyfge",cms?.theunconventionalicon?.img);
    return (



        <>
        <Header />
        <div className="fm_community_membership">
            <div className="container-fluid" data-aos="fade-up">
                <img src={require("../assets/images/community.png")} className="img-fluid" />
                <h1 className="fm_big_size">Community Membership</h1>
                <div className="fm_about_us">
                    <h1>About Us</h1>
                <p className="mar_bot_0">{ReactHtmlParser(cms?.aboutus?.answer)}</p>
                </div>
            </div>
            <div className="container-fluid" data-aos="fade-up">
                <div className="fm_unconventional_icon">
                    <div className="row">
                        <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-12 fm_icon">
                            <h1>The Unconventional Icon</h1>
                            <p>{ReactHtmlParser(cms?.theunconventionalicon?.answer)}</p>
                        </div>
                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 fm_icon_img">
                            <img src={`${config.ImG}/cmsimg/${cms?.theunconventionalicon?.img}`} className="img-fluid" />
                        </div>

                    </div>
                </div>
            </div>
            <div className="container-fluid" data-aos="fade-up">
                <div className="fm_the_freaky_feko_community">
                   <h1>{cms?.thefreakyfekocommunity?.question}
                    {/* The Freaky Feko Community */}
                    </h1>
                    <div className="row">
                   <p>{ReactHtmlParser(cms?.thefreakyfekocommunity?.answer)}</p>
                   {cms?.thefreakyfekocommunity &&  cms?.thefreakyfekocommunity?.img.map((item,index)=>(
                    <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 feko_community">
                        <div className="fm_feko_community">
                            <img src={`${config.ImG}/cmsimg/${cms?.thefreakyfekocommunity?.img[index]}`} className="img-fluid" data-aos="zoom-in"/>
                            <p>{cms?.thefreakyfekocommunity?.subanswer[index]}</p>
                            </div>
                    </div>

                   ))}
                   </div>
                 {/* <div className="row">
                    <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 feko_community">
                        <div className="fm_feko_community">
                            <img src={`${config.ImG}/cmsimg/${cms?.thefreakyfekocommunity?.img[0]}`} className="img-fluid" data-aos="zoom-in"/>
                            <p>{ReactHtmlParser(cms?.thefreakyfekocommunity?.subanswer[0])}</p>
                            </div>
                    </div>
                    <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 fm_community">
                    <div className="fm_feko_community">
                            <img src={`${config.ImG}/cmsimg/${cms?.thefreakyfekocommunity?.img[1]}`} className="img-fluid" data-aos="zoom-in" />
                            <p>{ReactHtmlParser(cms?.thefreakyfekocommunity?.subanswer[1])}</p>
                            </div>
                    </div>
                    <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 fm_community">
                        <div className="fm_feko_community">
                            <img src={require("../assets/images/star.png")} className="img-fluid" data-aos="zoom-in" />
                            <p>{ReactHtmlParser(cms?.thefreakyfekocommunity?.subanswer[2])}</p>
                            </div>
                    </div>
                    <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 fm_community">
                    <div className="fm_feko_community">
                            <img src={require("../assets/images/idea.png")} className="img-fluid" data-aos="zoom-in" />
                            <p>{ReactHtmlParser(cms?.thefreakyfekocommunity?.subanswer[3])}</p>
                            </div>
                    </div>
                 </div> */}
                </div>
            </div>
            <div className="container-fluid" data-aos="fade-up">
            <div className="fm_why_become_a_member">
                <h1>Why Become a member</h1>
                <p>{ReactHtmlParser(cms?.whybecomeamember?.answer)}</p>
                <div className="fm_become_a_member">
                <ul>
                    {cms?.whybecomeamember && cms?.whybecomeamember?.subanswer?.map((item,index)=>(
                        <li>{item}</li>
                    ))}
                    {/* <li>{cms?.whybecomeamember?.subanswer[0]}</li>
                    <li>{ReactHtmlParser(cms?.whybecomeamember?.subanswer[1])}</li>
                    <li>{ReactHtmlParser(cms?.whybecomeamember?.subanswer[2])}</li> */}
                </ul>
                </div>
            </div>
            </div>
            <div className="container-fluid" data-aos="fade-up">
                <div className="fm_how_to_join_freaky_feko_community">
                    <h1>How to join freaky feko community</h1>
                    <p>{ReactHtmlParser(cms?.howtojoinfreakyfekocommunity?.answer)}</p>
                    <p className="divider"></p>
                    <div className="fm_steps">
                        <div className="row">

{cms?.howtojoinfreakyfekocommunity && cms?.howtojoinfreakyfekocommunity?.subanswer.map((item,index)=>{
    return(
     <div className="col-12 col-xl-3 co-lg-3 col-md-3 col-sm-6 col-xs-12 become_a_member">
     <img src={`${config.ImG}/cmsimg/${cms?.howtojoinfreakyfekocommunity?.img[index]}`} className="img-fluid" data-aos="zoom-in"/>
     <p>{item}</p>   
</div>)
})}

                            {/* <div className="col-12 col-xl-3 co-lg-3 col-md-3 col-sm-6 col-xs-12 become_a_member">
                                 <img src={require("../assets/images/group1.png")} className="img-fluid" data-aos="zoom-in"/>
                                 <p>{ReactHtmlParser(cms?.howtojoinfreakyfekocommunity?.subanswer[0])}</p>   
                            </div>
                            <div className="col-12 col-xl-3 co-lg-3 col-md-3 col-sm-6 col-xs-12 become_a_member">
                                 <img src={require("../assets/images/group2.png")} className="img-fluid" data-aos="zoom-in" />
                                 <p>{ReactHtmlParser(cms?.howtojoinfreakyfekocommunity?.subanswer[1])}</p>   
                            </div>
                            <div className="col-12 col-xl-3 co-lg-3 col-md-3 col-sm-6 col-xs-12 become_a_member">
                                 <img src={require("../assets/images/group3.png")} className="img-fluid" data-aos="zoom-in" />
                                 <p>{ReactHtmlParser(cms?.howtojoinfreakyfekocommunity?.subanswer[2])}</p>   
                            </div>
                            <div className="col-12 col-xl-3 co-lg-3 col-md-3 col-sm-6 col-xs-12 become_a_member">
                                 <img src={require("../assets/images/follow-us.png")} className="img-fluid" data-aos="zoom-in" />
                                 <p>{ReactHtmlParser(cms?.howtojoinfreakyfekocommunity?.subanswer[3])}</p>   
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid" data-aos="zoom-in">
            <div className="fm_why_become_a_member fm_future">
                <h1>The Future of The Freaky Feko</h1>
                <p>{ReactHtmlParser(cms?.thefutureofthefreakyfeko?.answer)}</p>
                <div className="fm_become_a_member">
                <ul>
                    {cms?.thefutureofthefreakyfeko && cms?.thefutureofthefreakyfeko.subanswer.map((item,index)=>(
                        <li>{item}</li>
                    ))}
                    {/* <li>{ReactHtmlParser(cms?.thefutureofthefreakyfeko?.subanswer[0])}</li>
                    <li>{ReactHtmlParser(cms?.thefutureofthefreakyfeko?.subanswer[1])}</li>
                    <li>{ReactHtmlParser(cms?.thefutureofthefreakyfeko?.subanswer[2])}<Link to="#" style={{color:"#F9F871"}}> Join the community today and be part of this exciting journey!</Link></li> */}
                </ul>
                </div>
            </div>
            </div>
            <div className="container-fluid" data-aos="zoom-in">
                <div className="fm_benefits">
                <h1>Benefits</h1>
                <p>When acquiring a TFF, not only are you gaining access to a thriving, supportive community and helping to spotlight people everywhere, but we also offer our holders a range of exclusive perks</p>
                { (cms && cms?.votingrights) && <OwlCarousel className='owl-theme' {...options}>
                    <div class='item'>
                        <div className="fm_plan">
                            {console.log("sefukhaseuidyaswdaaw",cms)}
                            <img src={`${config.ImG}cmsimg/${cms?.votingrights?.img[0]}`} className="img-fluid" data-aos="zoom-in"/>
                            <h5>{cms?.votingrights?.question}</h5>
                            <p>{ReactHtmlParser(cms?.votingrights?.answer)}
                                {/* As a TFF holder, you will have the right to vote on important decisions related to the future of the project, such as protocol upgrades, changes to tokenomics, and community initiatives. */}
                            </p>
                        </div>
                    </div>
                    <div class='item'>
                    <div className="fm_plan">
                            <img src={`${config.ImG}cmsimg/${cms?.discountsandrewards?.img[0]}`} className="img-fluid" data-aos="zoom-in" />
                            <h5>{cms?.discountsandrewards?.question}</h5>
                            <p>{ReactHtmlParser(cms?.discountsandrewards?.answer)}
                                {/* TFF holders can enjoy discounts and exclusive rewards on products and services offered by partner companies and merchants within the TFF ecosystem. */}
                            </p>
                        </div>
                    </div>
                    <div class='item'>
                    <div className="fm_plan">
                            <img src={`${config.ImG}cmsimg/${cms?.stakingrewards?.img[0]}`} className="img-fluid" data-aos="zoom-in" />
                            <h5>{cms?.stakingrewards?.question}</h5>
                            <p>{ReactHtmlParser(cms?.stakingrewards?.answer)}
                                {/* By staking your TFF tokens, you can earn rewards in the form of additional tokens, which can help increase your holdings and provide passive income. */}
                            </p>
                        </div>
                    </div>
                    <div class='item'>
                    <div className="fm_plan">
                            <img src={`${config.ImG}cmsimg/${cms?.digitalplatforms?.img[0]}`} className="img-fluid" data-aos="zoom-in" />
                            <h5>{cms?.digitalplatforms?.question}</h5>
                            <p>{ReactHtmlParser(cms?.digitalplatforms?.answer)}
                                {/* By staking your TFF tokens, you can earn rewards in the form of additional tokens, which can help increase your holdings and provide passive income. */}
                            </p>
                        </div>
                    </div>
                    
                </OwlCarousel>}
                </div>                
            </div>
            <div className="container-fluid" data-aos="zoom-in">
                <div className="fm_sign_up">
                    <h1>Sign Up</h1>
                <div className="fm_contact_form">
                        <div className="row mt-4">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 fm_contact_form_name">
                                <label className="pb-4">Email</label>
                                <input type="email" id="email"  value={memberinfo?.email} onChange={(e) => onchangehandler(e)} className="form-control" placeholder="Email" />
                                <span className="error_txt_clr">{error?.email}</span>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 fm_contact_form_email">
                            <label className="pb-4">Wallet</label>
                                
                                <input type="text"  value={memberinfo?.walletaddress} id="walletaddress" onChange={(e) => onchangehandler(e)}  className="form-control" placeholder="Wallet" />
                                <span className="error_txt_clr">{error?.walletaddress}</span>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 fm_contact_form_message">
                                <label className="pb-4">Owned NFT ID</label>
                                <input type="text"  value={memberinfo?.nftid} id="nftid" onChange={(e) => onchangehandler(e)} className="form-control" placeholder="ID..." />
                                <span className="error_txt_clr">{error?.nftid}</span>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 fm_contact_form_submit">
                            <ul className="d-flex fm_header_button justify-content-end">
                                <li className="me-0">
                                    
                                    {memberinfo?.email == "" && memberinfo?.walletaddress == "" && memberinfo?.nftid == ""  && error?.email== "" &&  error?.walletaddress== "" &&  error?.nftid==""?
                                       <> <button  className="btn btn-warning primary">
                                        <span>Confirm</span>
                                        </button></> : <>
                                    <button  onClick={() => onsubmithandler()} className="btn btn-warning primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                        <span>Confirm</span>
                                        </button> </>}
                                </li>
                                {/* <li>
                                    <button className="btn btn-warning secondary">
                                        <span>Shop</span>
                                        </button>
                                </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        
        {/* confirm modal */}

        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5 text-center w-100" id="staticBackdropLabel">Community Membership</h1>
        {/* <button type="button"  class="btn-close modal_cls_btn" data-bs-dismiss="modal" aria-label="Close"></button> */}
        <i class="bi bi-x modal_cls_btn" data-bs-dismiss="modal" aria-label="Close"/>
      </div>
      <div class="modal-body">
      {/* you added in the community */}
      {modelmsg=== "you added in the community" ? <><span>Welcome to the TFF community! We are thrilled to have you join us on this exciting journey. We believe that The
Freaky Feko has the potential to be a game-changer in the world!</span></> : <><span>Already you are joined in our community</span></>}
        {/* In this session I need to declare <b>"Babu"</b> is an good developer. */}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        {/* <button type="button" class="btn btn-dark">Understood</button> */}
      </div>
    </div>
  </div>
</div>
        {/* end of confirm modal */}
        <Footer />
        </>
    )
}