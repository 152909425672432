import React, { useState } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { useLocation,useHistory  } from "react-router-dom";
import config from '../lib/config'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


import AOS from "aos";




export default function Blog() {
    const location=useLocation()
    console.log("location from blog",location,"gg",location.state)
    // const {testvalue}=location.state
    // const history=useHistory()
    // console.log('useh',testvalue)
      const article=location.state;
    React.useEffect(() => {
        AOS.init({
            once: true,
            offset: 0,
            duration: 300,
            easing: 'ease-in-quad',
            delay: 0,
        });
    }, []);

    // const [blogcard, setblogcard] = useState([
    //     {
    //         "id": 1,
    //         "image": require("../assets/images/Rectangle 152.png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 2,
    //         "image": require("../assets/images/Rectangle 152 (1).png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 3,
    //         "image": require("../assets/images/Rectangle 152 (2).png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 4,
    //         "image": require("../assets/images/Rectangle 152.png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 5,
    //         "image": require("../assets/images/Rectangle 152 (1).png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 6,
    //         "image": require("../assets/images/Rectangle 152 (2).png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 7,
    //         "image": require("../assets/images/Rectangle 152.png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 8,
    //         "image": require("../assets/images/Rectangle 152 (1).png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 9,
    //         "image": require("../assets/images/Rectangle 152 (2).png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },

    // ])

   

    return (
        <>

            <Header />


            <div className="fm_faq blogdetail">
                <div className="container-fluid ">

                    <div className="blogdet">
                        <h1>{article.heading}</h1>

                        <img className="img-fluid blogdtl_img mt-3 mb-3" data-aos="zoom-in" data-aos-duration="1000" src={`${config.ImG}${article.img}`} />
                        {/* <h3 className="img-fluid mt-3 mb-3" data-aos="fade-up" data-aos-duration="1500">Cool Cats Teams Up with Ledger to Release Cool<br></br>
                            Cats Ledger Nano S Plus</h3> */}
<p className="text-left ptag mt-3 mb-5" data-aos="fade-up" data-aos-duration="1500">{ReactHtmlParser(article.content)}</p>
                        {/* <p className="text-left ptag mt-3 mb-5" data-aos="fade-up" data-aos-duration="1500">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                            The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                            <br></br><br></br>
                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.<br></br>
                            The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p> */}
                    </div>
                </div>
            </div>




            <Footer />
        </>
    )
}