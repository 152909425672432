import React, { useState } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";

import Accordion from 'react-bootstrap/Accordion';
import AOS from "aos";
import { getfaq,getarticle } from '../axioscall/user';
import config from '../lib/config'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom';

import OwlCarousel from "react-owl-carousel-rtl";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



export default function Blog() {
    const [skip,setSkip]=useState(0);
    const [limit,setLimit]=useState(6)
    const [status,setStatus]=useState(true)

    React.useEffect(() => {
        AOS.init({
            once: true,
            offset: 0,
            duration: 300,
            easing: 'ease-in-quad',
            delay: 0,
        });
        getarticles()
    }, []);

    const [blogcard, setblogcard] = useState([])
    //     {
    //         "id": 1,
    //         "image": require("../assets/images/Rectangle 152.png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 2,
    //         "image": require("../assets/images/Rectangle 152 (1).png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 3,
    //         "image": require("../assets/images/Rectangle 152 (2).png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 4,
    //         "image": require("../assets/images/Rectangle 152.png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 5,
    //         "image": require("../assets/images/Rectangle 152 (1).png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 6,
    //         "image": require("../assets/images/Rectangle 152 (2).png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 7,
    //         "image": require("../assets/images/Rectangle 152.png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 8,
    //         "image": require("../assets/images/Rectangle 152 (1).png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },
    //     {
    //         "id": 9,
    //         "image": require("../assets/images/Rectangle 152 (2).png"),
    //         "title": "This Week In Web3 and NFTs",
    //         "date": "May 17, 2023",
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    //     },

    // ])

    // const [faq, setFaq] = React.useState([])

    // const getfaqlist = async () => {
    //     var resp = await getfaq();
    //     console.log("faq", resp.data);
    //     if (resp.status) {
    //         setFaq(resp.data)
    //     }
    // }

    const options1 = {
        loop: true,
        margin: 20,
        nav: true,
        dots: true,
        smartSpeed: 1200,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    }


    const getarticles=async()=>{
        var address = { skip, limit }
        var resp=await getarticle(address);
        console.log("articleaaa",resp);
        if(resp.status){
            setblogcard(resp.data)
            setSkip(skip + limit)
            // setLimit(limit)
            resp?.data?.length == 0 ? setblogcard(resp.data) : setblogcard([...blogcard, ...resp.data])
        }
        else{
            setStatus(resp.status)
        }
    }

    return (
        <>

            <Header />


            <div className="fm_faq blog">
                <div className="container-fluid">
                    <img src={require("../assets/images/NEWS.png")} className="img-fluid" />
                    <h1 data-aos="fade-left">News</h1>

                    {/* <div>
                        <OwlCarousel className='owl-theme' {...options1}>
                            {blogcard.map((e, i) =>
                                <div class='item' data-aos="zoom-in">
                                    <div className="caro">
                                        <div className="card slidercard">
                                            <div className="row align-items-center">
                                                <div className="col-md-6">
                                                    <img src={e.image} className="img-fluid mb-2" />
                                                </div>
                                                <div className="col-md-6">
                                                    <h2 className="slider-title mb-2">{e.title}</h2>
                                                    <p className="date">{e.date}</p>
                                                    <p className="datep">{e.description}</p>
                                                    <ul className="fm_header_button">
                                                        <li className="">
                                                            <button className="btn btn-warning primary">
                                                                <Link to="/blogdetail"><span>View Post</span></Link>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </OwlCarousel>
                    </div> */}

                    <div className="row mt-5 mb-5">
                        {blogcard.length!=0 && blogcard?.map((e, i) =>
                            <div className="col-md-4 col-sm-6 mb-3">
                                <Link to={{pathname:"/blogdetail",state:e}}>
                                    <Card className="blog-card" data-aos="fade-up" data-aos-duration="1200">
                                        <Card.Img className="blogcardimg" variant="top" src={`${config.ImG}${e.img}`} />
                                        <Card.Body>
                                            <Card.Title>{e?.heading}</Card.Title>
                                            {/* {new Date(e.date)} */}
                                            <p className="dateblog mb-2">{e.date && `${new Date(e?.date).toLocaleString('default', { month: 'long' })}/${new Date(e?.date).getDate()}/${new Date(e?.date).getFullYear()}`}</p>
                                            <Card.Text>
                                            <span>{ReactHtmlParser(e.content.slice(0,15))}</span>
                                            </Card.Text>

                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        )}
                    </div>


                    <div className="mt-2 pb-5">
                        <ul className="fm_header_button">
                            <li className="">
                                {status===true ? <button className="btn btn-warning primary" onClick={()=>getarticles()}style={{ margin: "auto" }}>
                                    <span>Next</span>
                                </button>: ""}
                            </li>
                        </ul>
                    </div>


                </div>
            </div>




            <Footer />
        </>
    )
}