import React, { useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import * as userctrl from '../../axioscall/user'
import { HashLink } from "react-router-hash-link";
export default function Footer() {
    const initial=[
        {"link" : "","website" : "twitter"},
        {"link" : "","website" : "instagram"},
        {"link" : "","website" : "facebook"},
        {"link" : "","website" : "tiktok"},
        {"link" : "","website" : "youtube"},
        {"link" : "","website" : "telegram"},
        {"link" : "","website" : "discord"},
    ]
    const [mail, setMail] = useState('')
    const [status, setStatus] = useState(true)
    const [sociallinks, setLinks] = useState(initial)
    const [footer, setFooter] = useState([])
    React.useEffect(() => {
        getsociallink()
        getcmsfooter();
        AOS.init({
            once: true
        });
    }, []);

    function ValidateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            setStatus(true)
            return (true)
            
        }
        setStatus(false)
        // alert("You have entered an invalid email address!")
        return (false)
    }
    const onSubmit = async () => {
        console.log("mail", mail)
        var verify = await ValidateEmail(mail)
        if (verify) {
            var data = { "email": mail }
            var resp = await userctrl.subscribersave(data);
            if (resp.status) {
                
                console.log("success add subscriber")
                setMail('')

            }
        }
        else {

            console.log("email not")
        }
    }

    const getcmsfooter = async () => {
        console.log("nfsdn")

        var resp = await userctrl.getcms();
        console.log("cms", resp);
        if (resp.status) {
            setFooter(resp.data)
        } else {

        }
    }




    const getsociallink = async () => {
        var resp = await userctrl.getsociallink();
        console.log("socaial", resp);
        setLinks(resp.data)

    }
    return (
        <>
            <div className="fm_footer_bg" data-aos="fade-up">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 fm_footer_image">
                            <Link to="/"><img src={require("../../assets/images/logo.png")} className="img-fluid" /></Link>
                            <div className="fm_footer_address">
                                {/* {footer?.map((item)=>{
                                    console.log("itemans",item.answer , item?.slug==="footer")
                                    item?.slug==="footer" ? <><p>{ReactHtmlParser(item.answer)}</p></> : <><span> </span></>
                                })} */}
                                <p className="address_txt">{ReactHtmlParser(footer[1]?.answer)}</p>
                                {/* <p>The Freaky Studios LLC</p>
                                <p>166 Greary ST. 15 TH Floor 65</p>
                                <p>San Fransico, CA 94108, USA</p> */}
                            </div>
                        </div>
                        <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 fm_footer_links">
                            <ul>

                                <li><HashLink to="/home/#aboutlink">About</HashLink></li>
                                <li><HashLink to="/home/#the_team">Team</HashLink></li>

                                <li><HashLink smooth to="/home/#roadmap_link">Road Map</HashLink></li>

                                <li><Link to="/gallery">Gallery</Link></li>

                                {/* <li><Link to="/blog">Blog</Link></li> */}

                            </ul>
                        </div>
                        <div className="col-12 col-xl-6 col-lg-6 col-md-4 col-sm-12 col-xs-12 fm
                _newsletter text-right">
                            <h1>Newsletter</h1>
                            {/* {"footer?.map((item)=>{
                                item?.slug==="newsletter" && <p>{ReactHtmlParser(item?.answer)}</p>
                            })}" */}
                            <p>{ReactHtmlParser(footer[0]?.answer)}</p>
                            {/* <p>We’re a team of creatives who are excited about unique ideas and create consectetur adipiscing elit. Etiam vitae purus a lectus semper.</p> */}
                            <div className="fm_subscriber d-flex align-items-center">
                                <input type="text" placeholder="Enter Your Email" value={mail} onChange={(e) => setMail(e.target.value)} className="form-control" />
                                {mail != '' && status == true ? <button className="btn btn-warning" data-bs-toggle="modal"  data-bs-target="#staticBackdro" onClick={() => onSubmit()}>Subscribe</button> :  <button className="btn btn-warning" >Subscribe</button>}
                            </div>
                          {status != true ?<span className="error_txt_clr">Email not valid</span> : " " }
                            <ul className="d-flex mt-5 fm_social_icons">

                                {sociallinks?.map((item, id) => {
                                    return (
                                        <>
                                                <a href={item.link} target="blank">
                                                    <li><i className={`fab fa-${(item.website).toLowerCase()}`}/></li>
                                                </a>
                                        </>
                                        //     (item.website).toLowerCase()==="twitter"&&
                                        //     <a href={item.link}>
                                        //     <li><i className="fab fa-twitter"></i></li> 
                                        //     </a>||

                                        //     (item.website).toLowerCase()==="instagram"&&
                                        //     <a href={item.link}>
                                        //     <li><i className="fab fa-instagram"></i></li> 
                                        //     </a>||
                                        //   item.website.toLowerCase()==="youtube" &&
                                        //   <a href={item.link} target="_blank"><li><i className="fab fa-youtube"></i></li></a> ||
                                        //          (item.website).toLowerCase()==="discord"&&
                                        //          <a href={item.link}>
                                        //          <li><i className="fab fa-discord"></i> </li>
                                        //          </a>||
                                        //           (item.website).toLowerCase()==="telegram"&&
                                        //            <a href={item.link}>
                                        //            <li><i className="fab fa-telegram-plane"></i></li> 
                                        //            </a>||
                                        //            (item.website).toLowerCase()==="facebook"&&
                                        //            <a href={item.link}>
                                        //            <li><i className="fab fa-facebook"></i></li> 
                                        //            </a>||
                                        //         //    (item.website).toLowerCase()==="linkedin"&&
                                        //         //    <a href={item.link}>
                                        //         //    <li><i className="fab fa-linkedin"></i></li> 
                                        //         //    </a>||
                                        //            (item.website).toLowerCase()==="tiktok"&&
                                        //            <a href={item.link}>
                                        //            <li><i className="fab fa-tiktok"></i></li> 
                                        //            </a>
                                    );
                                })}
                                <li className="opensea_foot">
                                    <a href=" https://opensea.io/TheFreakyStudios" target="_blank">
                                    <img className="opensea_footer" src={require('../../assets/images/opensea.png')}/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="fm_copyright">
                        <p>Copyrights&copy;2023.All rights reserved</p>
                    </div>
                </div>
                {/* <div className="fm_clip_mask">
            <img src={require("../../assets/images/clip-mask.png")} className="img-fluid" />
        </div> */}
            </div>

            {/* confirm modal */}

            <div class="modal fade" id="staticBackdro" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title text-center w-100 fs-5" id="staticBackdropLabel"> Hello Subscriber </h1>
                            {/* <button type="button"  class="btn-close modal_cls_btn" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            <i class="bi bi-x modal_cls_btn" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div class="modal-body">
                            {/* you added in the community */}
                            {/* {modelmsg} */}
                            <p>We want to extend a warm welcome for subscribing to The Freaky Feko newsletter. Thank you for joining our
community of like-minded individuals who are passionate about entertainment, self-expression, and thought-
provoking content.</p>
                            {/* In this session I need to declare <b>"Babu"</b> is an good developer. */}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            {/* <button type="button" class="btn btn-dark">Understood</button> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* end of confirm modal */}

        </>
    )
}