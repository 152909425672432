import axios from 'axios'
import config from '../lib/config'


export const searchfilter=async(filter)=>{
    console.log("filter",filter)
        try{
            var resp = await axios({
                "method":"POST",
                "url":`${config.USERAPI}/searchfilter`,
                "data":filter
            })
        
            return resp.data
        
            
        }
        catch(err){
           throw err
        }
        
}

export const getnftdetails=async(address)=>{
   
        try{
            var resp = await axios({
                "method":"GET",
                "url":`${config.USERAPI}/getnftdetail`,
                "params":address
            })
        
            return resp.data  
        }
        catch(err){
           throw err
        }
        
}

export const getcatlist=async()=>{
        try{
            var resp = await axios({
                "method":"GET",
                "url":`${config.USERAPI}/getcatlist`,
            })
        
            return resp.data.data  
        }
        catch(err){
           throw err
        }
        
}

export const savenftdetails=async(data)=>{
    console.log("apinft",data)
    try{
        var resp = await axios({
            "method":"post",
            "url":`${config.USERAPI}/savenft`,
            "data":data
        })
    
        return resp.data  
    }
    catch(err){
       throw err
    }
}

export const geturl=async()=>{
    try{
        var resp = await axios({
            "method":"get",
            "url":`${config.USERAPI}/geturl`,
        })
    
        return resp.data  
    }
    catch(err){
       throw err
    }
}

export const getyoutubeurl=async()=>{
    try{
        var resp = await axios({
            "method":"get",
            "url":`${config.USERAPI}/getyoutubeurl`,
        })
    
        return resp.data  
    }
    catch(err){
       throw err
    }
}

