import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import AOS from "aos";
export default function FF(){
    React.useEffect(() => {
        AOS.init({
            once: true,
            offset: 0,
            duration: 300,
            easing: 'ease-in-quad',
            delay: 0,
        });
      }, []);
    return(
        <>
        <Header />
        <div className="fm_market">
            <div className="container-fluid">
            <img src={require("../assets/images/myff.png")} className="img-fluid" />
            <h1>My Freaky Feko </h1>
            <div className="fm_comming_description text-center">
                <p>A place to customize your Art with FF digital assets and wearables.</p>
            </div>
            <div className="fm_comming_soon padding_minus"  data-aos="fade-up">
                 <div className="fm_freaky_comming_soon_image">
                  <img src={require("../assets/images/comming-soon-line1.png")} className="img-fluid" data-aos="fade-left"/>
                  <h1>Coming Soon</h1>
                  <img src={require("../assets/images/comming-soon-line1.png")} className="img-fluid" data-aos="fade-right"/>
                </div>   
            </div>
           
            </div>
        </div>
        <Footer />
        </>
    )
}