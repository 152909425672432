import React from "react";
import { NavLink, Link, } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import * as tokenctrl from '../../axioscall/token'
import $ from "jquery";
import { useState } from "react";
export default function Header() {
    // const navigate = useNavigate();
    const history = useHistory();
    const [header, setHeader] = React.useState(false);
    const [url,seturl]=useState('')
    window.$(window).scroll(function () {
        var scroll = $(window).scrollTop();
        // console.log("Scroll",scroll);
        if (scroll >= 50) {
            $('.navbar').addClass('scroll');
        } else {
            $('.navbar').removeClass('scroll');
        }
    });
    React.useEffect(()=>{
        geturl()
    },[])

    const geturl = async () => {
        var resp = await tokenctrl.geturl()
        console.log("++++", resp, resp.data[0].Url)
        seturl(resp.data[0].Url)
    }
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [menu, setMenu] = React.useState(5);
    return (
        <>

        <nav class="navbar navbar-expand-xl w-100 pt-4">
        <div className="container-fluid p-0">
        <div className="logo">
        <Link to="/"> <img src={require("../../assets/images/logo.png")} className="img-fluid" width="110" height="110" /></Link>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
        onClick={() => setHeader(!header)}>
    <i class="fa fa-bars" aria-hidden="true"></i>
  </button>
        <div className={header == true ? 
        "collapse navbar-collapse w-100 justify-content-between active" :
        "collapse navbar-collapse w-100 justify-content-between"} id="navbarSupportedContent">
            <ul class="navbar-nav m-auto">

            <li  className="nav-item">
                {/* <Link to="">Roadmap</Link> */}
                {/* <a href="">Roadmap</a> */}
                <HashLink smooth to="/home/#roadmap_link">Roadmap</HashLink>

            </li>

            <li  className="nav-item">
                <NavLink to="/gallery">Gallery</NavLink>
            </li>

            <li  className="nav-item"
            //  onClick={()=>
            //    navigate("/home/event_navi")}
                >
                {/* <NavLink  to='' >Events</NavLink> */}
                <HashLink smooth to="/home/#events">Events</HashLink>
            </li>

            <li  className="nav-item">
                <NavLink exact to="/market">Shop</NavLink>
            </li>
            
            <li className="nav-item">   
                <NavLink exact to="/ff">My Freaky Feko</NavLink>
            </li>

            <li  className="nav-item">
                <NavLink to="/faq">FAQ</NavLink>
            </li>
           
           
           
           
          
           
            </ul>
            <ul className="d-flex fm_header_button">
                {/* <li className="me-4">

                <Link to="/community"><button className="btn btn-warning secondary">
                    <span>Community</span>
                    </button>
                    </Link>
            </li>  */}
                            <li className="me-2">
                                <button className="btn btn-warning primary">
                                    <NavLink exact to="/community"><span>Community</span></NavLink>
                                </button>
                            </li>

                            <li className="me-2">
                                <button className="btn btn-warning secondary">
                                    <NavLink exact to="/tff"><span>TFS Foundation</span></NavLink>
                                </button>
                            </li>

                            <li className="">
                            <a href={url} target="balnk"> <button className="btn btn-warning primary">
                                            <span>Mint</span>
                                        </button></a>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
            <div id="myModal" class="modal fade" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            {/* <button type="button" class="close" data-dismiss="modal">&times;</button> */}
                            <h4 class="modal-title">Connect Wallet</h4>
                        </div>
                        <div class="modal-body pt-5">
                            <div className="row">
                                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 fm_metamask_wallet">
                                    <div className="fm_connect">
                                        <img src={require("../../assets/images/metamask.png")} className="img-fluid" />
                                        <p>Metmask</p>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 fm_metamask_wallet">
                                    <div className="fm_connect pt-4">
                                        <img src={require("../../assets/images/walletconnect.png")} className="img-fluid" />
                                        <p>Wallet Connect</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer m-auto">
                            <ul className="d-flex fm_header_button">
                                <li>
                                    <button className="btn btn-warning secondary">
                                        <span>Connect Wallet</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <div id="myModal1" class="modal fade" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            {/* <button type="button" class="close" data-dismiss="modal">&times;</button> */}
                            <h4 class="modal-title">Connect Wallet</h4>
                        </div>
                        <div class="modal-body pt-5 pb-5">
                            <div className="row">
                                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 fm_metamask_wallet">
                                    <div className="fm_connected">
                                        <img src={require("../../assets/images/metamask.png")} className="img-fluid" />
                                        <p>Wallet Connected</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}