import React, { useState, useEffect } from "react";
import Header from "./common/Header";
import ReactWebMediaPlayer from 'react-web-media-player';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
// import ReactReadMoreReadLess from "react-read-more-read-less";
import $ from "jquery";
import Countdown from 'react-countdown';
import { Link, NavLink } from "react-router-dom";
import config from '../lib/config'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import ReactFlipCard from 'reactjs-flip-card'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard, Mousewheel } from "swiper/core";
// import mCustomScrollbar from 'malihu-custom-scrollbar-plugin';
import OwlCarousel from "react-owl-carousel-rtl";
// import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../assets/css/jquery.mCustomScrollbar.css';
import '../assets/css/jquery.mCustomScrollbar.min.css';
import { useLocation, useParams } from "react-router-dom";


import * as tokenctrl from '../axioscall/token'
import * as userctrl from '../axioscall/user'
import { getimages } from "../axioscall/user";

// import ScrollArea from "react-scrollbar";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import AOS from "aos";
import Footer from "./common/Footer";
import FlipCard from 'flip-card-react';
// import FirstImage from "../assets/images/Meunier.png";
// import SecondImage from "../assets/images/Eva.png";
// import ThirdImage from "../assets/images/Miguel.png";
import { getevents } from "../axioscall/user";
// import { validate } from "../../../backend/Model/admin_models/urlSchema";

export default function Home() {
    const [image, setImage] = React.useState(1);

    const [first, setFirst] = React.useState();
    const [second, setSecond] = React.useState();
    const [third, setthird] = React.useState();
    const [youtube,setYoutubeurl]=useState('')
    const [title, setTitle] = useState("The Mysetrious Second");
    const [desc, setDesc] = useState("Mysterious Second");
    const [transition, setTransition] = useState("");
    const [trans1, setTrans1] = useState("");
    const [trans2, setTrans2] = useState("");
    const [trans3, setTrans3] = useState("");
    const [days, setDays] = useState('');
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');
    const [sec, setSec] = useState('')
    const [active, setactive] = React.useState(0);
    const [images,setImages]=useState([])
    const [filters,setFilters]=useState({"about_feko" : {},
    "eva" :{},
    "meunier" : {},
    "Magnanimous" :{},
    "question":{},
    "FekoObjective_small" :{},
    "FekoObjective_big":{},
    "Feko_Mission" :{},
    "Feko_Value" :{},
    "mint_banner" :{},
    "Sneak_Peek": {}
 })
    React.useEffect(() => {
        var elem = document.getElementById("first_card");
        if (typeof elem !== 'undefined' && elem !== null) {
            // console.log(elem.offsetLeft);
            setoffset1(elem.offsetLeft)
        }
        var elem1 = document.getElementById("second_card");
        if (typeof elem1 !== 'undefined' && elem1 !== null) {
            //   console.log(elem1.offsetLeft);  
            setoffset2(elem1.offsetLeft)
        }
        var elem2 = document.getElementById("third_card");
        if (typeof elem2 !== 'undefined' && elem2 !== null) {
            //   console.log(elem2.offsetLeft);
            setoffset3(elem2.offsetLeft)
        }
    }, [])
    const [offset1, setoffset1] = React.useState(0);
    const [offset2, setoffset2] = React.useState(0);
    const [offset3, setoffset3] = React.useState(0);
    // console.log("Offset 1", offset1);
    // console.log("Offset 2", offset2);
    // console.log("Offset 3", offset3);
    //     React.useEffect(() => {
    //     if (trans1 == "trans1"){
    //             setTrans2("trans2");
    //             setTimeout(() => {
    //               setTrans2("");
    //             }, 500);
    //     }
    //     if (trans3 == "trans3"){
    //             setTrans2("trans2");
    //             setTimeout(() => {
    //               setTrans2("");
    //             }, 500);
    //     }   
    // })

    const transit = (number) => {
        setTrans1("trans" + number);
        setTrans2("trans2");
        setTimeout(() => {
            setTrans1("");
            setTrans2("");
        }, 1000);
    };
    const transit2 = (number) => {
        setTrans3("trans" + number);
        setTrans2("trans2");
        setTimeout(() => {
            setTrans3("");
            setTrans2("");
        }, 1000);
    };



    const location = useLocation();
    const params = useParams();


    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1,
        );
        if (window.location.href.lastIndexOf('#') > 0) {
            document.getElementById(href)?.scrollIntoView();
        }
    }, []);

    const [firsttitle, setFirstTitle] = React.useState("Mysterious Co-Founder");
    const [secondtitle, setSecondTitle] = React.useState("Euphoric Co-Founder ");
    const [thirdtitle, setthirdTitle] = React.useState("Magnanimous Co-Founder");
    const [firstsubtitle, setFirstsubTitle] = React.useState("The Freaky Meunier");
    const [secondsubtitle, setSecondsubTitle] = React.useState("The Freaky Eva ");
    const [thirdsubtitle, setthirdsubTitle] = React.useState("The Freaky Miguel");
    const[cms,setCms]=useState({})

    const [meunier,setMeunier] = React.useState("Meunier, also known as The Freaky Mysterious, is a co-founder with an enigmatic aura and a deep passion. With a flair for mystery affection, and compassion, Meunier brings a sense of wonder to our team and innovation to our mission. His unique perspective and creative instincts add depth and intrigue to our projects. His ability to think outside the box and challenge conventional norms inspires us to push boundaries and explore new possibilities. Meunier's dedication to crafting captivating narratives and exploring the unknown makes him an invaluable asset in delivering powerful messages that resonate deeply. Together, we embark on a journey to uncover hidden truths, spark imagination, create meaningful connections, and unravel the mysteries of the world through our art.");

    const [eva,setEva] = React.useState("Eva, also known as The Freaky Euphoric, is a visionary co-founder known for her vibrant personality and boundless creativity. With her innovative ideas and unwavering commitment, she drives our mission to inspire and empower. Eva encourages individuals to embrace their true selves and find euphoria in self-acceptance. Her infectious enthusiasm and belief in the power of creativity make her invaluable. Together, we aim to create a space where everyone can unleash their inner magic.");

    const [miguel,setMiguel] = React.useState("Miguel, also known as The Freaky Magnanimous, is a co-founder with a heart full of compassion and a desire to make a positive impact. With an innate ability to connect with people and understand their needs, Miguel brings a sense of generosity and kindness to our team. His selfless nature and commitment to serving others makes him an invaluable asset to our mission. Miguel's vision of creating a more inclusive and caring society drives our collective efforts to support mental health, social awareness, and well-being. Together, we strive to spread love, empathy, and support to individuals who need it most.");

    let SliderArray = [first, second, third];
    const [article, setArticle] = useState([])
    const [count, setCounter] = useState(0);
    const [url, seturl] = useState("")
    const addIndex = () => {
        if (count > SliderArray.length - 1) {
            setCounter = 4;
        } else {
            setCounter(count + 1);
        }
    };

    const subIndex = () => {
        if (count === 0) {
            setCounter = 0;
        } else {
            setCounter(count - 1);
        }
    };
    const MyItem = ({ children }) => (
        <div style={{ width: '70vw', height: 200, background: '#cccccc' }}>
            {children}
        </div>
    );
    React.useEffect(() => {
        // console.log(document.getElementById("first_card").offsetLeft,"first e")
        // console.log(document.getElementById("second_card").offsetLeft,"second e")


        AOS.init({
            once: true,
            offset: 0,
            duration: 300,
            easing: 'ease-in-quad',
            delay: 0,
        });
    }, []);
    React.useEffect(() => {
        // (function($){
        //     window.$(window).on("load",function(){
        //        var scrollPane = document.querySelector(".mCustomScrollbar");
        //          $(scrollPane).mCustomScrollbar();
        //      });
        //  })($);

        $('.scroll-up-button').on('click', function () {
            var y = $(".fm_scroll").scrollTop();
            $(".fm_scroll").scrollTop(y - 60);
        });


        $('.scroll-down-button').on('click', function () {
            var y = $(".fm_scroll").scrollTop();
            $(".fm_scroll").scrollTop(y + 60);
        });
    }, [])

    const options = {
        loop: true,
        margin: 20,
        nav: false,
        dots: false,
        smartSpeed: 1200,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    }
    const options1 = {
        loop: true,
        margin: 20,
        nav: false,
        dots: false,
        smartSpeed: 1200,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            300:{
                items:1
            },
            450:{
                items:2
            },
            600: {
                items: 2
            },
            750: {
                items: 2
            },
            992: {
                items: 3
            },
            1600: {
                items: 3
            }
        }
    }
    const options2 = {
        loop: true,
        margin: 20,
        nav: false,
        dots: false,
        smartSpeed: 1200,
        autoplay: true,
        animateIn: "slideInRight",
        animateOut: "slideOutLeft",
        responsive: {
            0: {
                items: 1
            },
            300:{
                items:1
            },
            450:{
                items:2
            },
            600: {
                items: 2
            },
            750: {
                items: 2
            },
            992: {
                items: 3
            },
            1600: {
                items: 3
            }
        }
    }
    useEffect(() => {
        geturl()
        getarticle()
        getyoutubeurl()
        getevent()
        getimage()
        getcmss()
    }, [])

    const getcmss = async () => {
        console.log("nfsdn")

        var resp = await userctrl.getcms();
        console.log("cms", resp);
        if(resp.status){
       setCms({
        aboutfeko: resp.data.filter(item=> item.slug=="aboutfeko")[0],
        team : resp.data.filter(item=> item.slug=="theteam")[0],
        eva: resp.data.filter(item=> item.slug=="eva"[0]),
        meunier: resp.data.filter(item=> item.slug=="meunier")[0],
        miguel :  resp.data.filter(item=> item.slug=="miguel")[0],
        thefreakyfekoobjective : resp.data.filter(item=> item.slug=="thefreakyfekoobjective")[0],
        thefreakyfekomission:resp.data.filter(item=> item.slug=="thefreakyfekomission")[0],
        thefreakyfekovalue:resp.data.filter(item=> item.slug=="thefreakyfekovalue")[0],
        sneakpeek         :resp.data.filter(item=> item.slug=="sneakpeek")[0],
        digitalplatforms1 : resp.data.filter(item=> item.slug=="digitalplatforms1")[0],
        digitalplatforms2 : resp.data.filter(item=> item.slug=="digitalplatforms2")[0],
        digitalplatforms3 : resp.data.filter(item=> item.slug=="digitalplatforms3")[0],
        digitalplatforms4 : resp.data.filter(item=> item.slug=="digitalplatforms4")[0],
        digitalplatforms5 : resp.data.filter(item=> item.slug=="digitalplatforms5")[0]
       })
       setEva(resp.data.filter(item=> item.slug=="eva")[0].answer)
       setMeunier(resp.data.filter(item=> item.slug=="meunier")[0].answer)
       setMiguel(resp.data.filter(item=> item.slug=="miguel")[0].answer)
       setFirst(`${config.ImG}/cmsimg/${ resp.data.filter(item=> item.slug=="meunier")[0].img}`)
setSecond(`${config.ImG}/cmsimg/${resp.data.filter(item=> item.slug=="eva")[0].img}`)
setthird(`${config.ImG}/cmsimg/${resp.data.filter(item=> item.slug=="miguel")[0].img}`)
setFirstTitle(resp.data.filter(item=> item.slug=="meunier")[0].subanswer[0])
setSecondTitle(resp.data.filter(item=> item.slug=="eva")[0].subanswer[0])
setthirdTitle(resp.data.filter(item=> item.slug=="miguel")[0].subanswer[0])



 


        }
       
    }

const getimage=async()=>{
    var resp=await getimages();
    console.log("imagedata",resp.data,resp);
    // var about_feko=resp.data.filter(character => character.content === 'about feko')
    // var eva=resp.data.filter(character => character.content === 'eva')
    // var meunier=resp.data.filter(character => character.content === 'meunier')
    // var Magnanimous=resp.data.filter(character => character.content === 'Magnanimous')
    var question=resp.data.filter(character => character.content === 'question')
    // var FekoObjective_small=resp.data.filter(character => character.content === 'FekoObjective_small')
    // var FekoObjective_big=resp.data.filter(character => character.content === 'FekoObjective_big')
    // var Feko_Mission=resp.data.filter(character => character.content === 'Feko_Mission')
    // var Feko_Value=resp.data.filter(character => character.content === 'Feko_Value')
    var mint_banner=resp.data.filter(character => character.content === 'mint_banner')
    // var Sneak_Peek=resp.data.filter(character => character.content === 'Sneak_Peek')
    // console.log(about_feko,eva,meunier,"VVAAADa")
   setFilters({
   "question":question[0],
   "mint_banner" :mint_banner[0],
})
// setFirst(`${config.ImG}${meunier[0].content}/${meunier[0].img}`)
// setSecond(`${config.ImG}${eva[0].content}/${eva[0].img}`)
// setthird(`${config.ImG}${Magnanimous[0].content}/${Magnanimous[0].img}`)

    setImage(resp.data)
}

    // Update the count down every 1 second
    // var x = setInterval(()=>{
    //     timer()
    // }, 1000);



    //  const timer=()=>{

    //     var countDownDate = new Date("Jan 5, 2024 15:37:25").getTime();
    //         // Get today's date and time
    //         var now = new Date().getTime();

    //         // Find the distance between now and the count down date
    //         var distance = countDownDate - now;

    //         // Time calculations for days, hours, minutes and seconds
    //         var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //         var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //         var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //         var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    //         // Output the result in an element with id="demo"
    //       //   document.getElementById("demo").innerHTML = days + "d " + hours + "h "
    //       //   + minutes + "m " + seconds + "s ";
    //       setDays(days);
    //       setHour(hours);
    //       setMinute(minutes)
    //       setSec(seconds)

    //         // If the count down is over, write some text 
    //         if (distance < 0) {
    //           clearInterval(x);
    //           document.getElementById("demo").innerHTML = "EXPIRED";
    //         }

    //  }

    const getarticle = async () => {
        console.log("hello")
        try {
            var resp = await userctrl.getarticle();
            console.log("getarticle", resp.data)
        }
        catch (err) {
            console.log(err, "err")
        }



        if (resp?.status) {
            setArticle(resp.data)
        }
    }
    const [events, setEvents] = useState([])
    const getevent = async () => {
        var resp = await getevents();
        console.log("kjkhsdfj evnet", resp)
        if (resp.status) {
            setEvents(resp.data)
        }
    }


    const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {
            return (
                <><ul className="d-flex align-items-center justify-content-between p-0 fm_count">

                    <li>
                        <p>Days</p>
                        <h1>{days}</h1>
                    </li>
                    <li>
                        <p>Hours</p>
                        <h1>{hours}</h1>
                    </li>
                    <li>
                        <p>Minutes</p>
                        <h1>{minutes}</h1>
                    </li>
                    <li>
                        <p>Second</p>
                        <h1>{seconds}</h1>
                    </li>
                </ul>
                </>)
        } else {
            // Render a countdown
            return (
                <>

                    <ul className="d-flex align-items-center justify-content-between p-0 fm_count">

                        <li>
                            <p>Days</p>
                            <h1>{days}</h1>
                        </li>
                        <li>
                            <p>Hours</p>
                            <h1>{hours}</h1>
                        </li>
                        <li>
                            <p>Minutes</p>
                            <h1>{minutes}</h1>
                        </li>
                        <li>
                            <p>Second</p>
                            <h1>{seconds}</h1>
                        </li>
                    </ul>
                </>)
        }
    };
    // const calculateTimeLeft = () => {
    //     const difference = +new Date(events?.date) - +new Date();
    //     let timeLeft = {};
    //     if (difference > 0) {
    //         timeLeft = {
    //           hours: Math.floor(difference / (1000 * 60 * 60)),
    //           minutes: Math.floor((difference / 1000 / 60) % 60),
    //           seconds: Math.floor((difference / 1000) % 60),
    //         };
    //       }

    //       return timeLeft;
    // };
    // const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    // useEffect(() => {
    //   setTimeout(() => {
    //     setTimeLeft(calculateTimeLeft());
    //   }, 1000);
    // });

    const getyoutubeurl = async () => {
        var resp = await tokenctrl.getyoutubeurl()
        console.log("yyoutube", resp)
        if(resp.data[0].Url.split('/')[2] != 'youtu.be'){

            var url=resp.data[0].Url.split('?')[0].replace("watch","embed")
            console.log("ggg",url,`${url}${resp.data[0].Url.split('?')[1]}`);
           setYoutubeurl(`${url}/${resp.data[0].Url.split('?')[1]}`)    
            }
            else{
               setYoutubeurl(`https://www.youtube.com/embed/${resp.data[0].Url.split('/')[3]}`)
               console.log("vaadaddd",`https://www.youtube.com/embed/${resp.data[0].Url.split('/')[3]}`);
            }
         
    }
    const geturl = async () => {
        var resp = await tokenctrl.geturl()
        // console.log("++++", resp, resp.data[0].Url)
        seturl(resp?.data[0]?.Url)
    }
    const [messageinfo, setMessageinfo] = useState({})
    const [msgname, setMsgname] = useState('');
    const [msgemail, setMsgemail] = useState('');
    const [msgsubject, setMsgsubject] = useState('');
    const [msg, setmsg] = useState('');
    const [error, setError] = useState({})
    const commentonchangehandler = async (e) => {
        e.preventDefault()
        console.log("dddddddddd")
        const { id, value } = e.target;
        setMessageinfo({ ...messageinfo, [id]: value })
        console.log("message", messageinfo)
    }
    const commmentonsubmithandler = async () => {
        console.log("jollyy")
        await Validation(msgemail, msgname)
        if (Object.keys(error).length === 0) {
            var messageinfo = {
                'name': msgname,
                "email": msgemail,
                "subject": msgsubject,
                "message": msg
            }
            var resp = await userctrl.addmessage(messageinfo);
            if (resp.status) {
                console.log("mesage added ")
                setMessageinfo({})
            }
        }
    }
    function Validation(email, name) {
        var err = {}
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            console.log("mailcheck")
            err.email = "email not valid"
            // setError({...error,"email":"email not valid"})
        }
        // console.log("hello dai vada", Web3.utils.isAddress(messageinfo.name))
        if (!/^[a-zA-Z]*$/.test(name)) {
            console.log("add check")
            err.walletaddress = "walletaddress not valid"
            // setError({...error,"address":"walletaddress not valid"})
        }
        setError(err)
    }
    // const transaction=()=>{
    //     let provider=
    // }

    return (
        <>
            <Header />
            <div className="fm_index_page">
                <div className="banner banner_bg_clr pt-5">
                    <div className="fm_banner" data-aos="zoom-in">
                        {/* <ReactWebMediaPlayer
                            // video="https://any-link.com/my-video.mp4"
                           video = {youtube}
                            // thumbnail={require("../assets/images/thumbnail.png")}
                            data-aos="fade-up"
                        /> */}
                        {youtube && youtube ? <iframe width="1200" height="600" src={`${youtube}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> :<video controls poster={require("../assets/images/grassthumb.jpg")}>
                            <source src={require('../assets/images/fekovideo.mp4')}></source>
                        </video> }
                        {/* <video controls poster={require("../assets/images/grassthumb.jpg")}>
                            <source src={ youtube }></source>
                        </video> */}
                    </div>
                    {/* <div className="container-fluid">
                        <p className="m-auto pt-5 fm_freaky_feko_para">Freaky Feko is an unconventional icon that will invade entertainment. He is an animated character that represents real-world issues with a satirical and humorous twist. His story is told across digital platforms such as apps, games, cartoons, talk shows, and more. His goal is to entertain and engage audiences with his edgy style and lovable personality. Get ready for a wild ride!</p>
                    </div> */}
                </div>
                <div className="fm_who_we" id="aboutlink">
                    <div className="about-container-fluid ms-0 me-auto ps-0" >
                        <div className="who-we" data-aos="fade-up">
                            <div className="who_we_are text-right">
                                <div className="container-fluid ms-auto me-0">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 about_us_header">
                                            {/* <p className="about_us">About Us</p> */}
                                            <h1>{cms?.aboutfeko?.question.trim()}
                                                {/* About Feko */}

                                            </h1>
                                            <p className="para">{cms?.aboutfeko &&  ReactHtmlParser(cms?.aboutfeko?.answer.replaceAll("\\s", ""))}
                                            </p>
                                            <div className="d-flex ">

                                                {/* <ul className="d-flex fm_header_button justify-content-center mt-5">
                                                    <li>
                                                        <button className="btn btn-warning secondary">
                                                            <span>Read More</span>
                                                        </button>
                                                    </li>
                                                </ul> */}


                                                {/* <button className="btn btn-warning secondary">
                            <span>Read More</span>
                            </button> */}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 about_us_header">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fm_image">
                                {console.log("abod",filters)}
                               {cms?.aboutfeko && <>
                               <img src={`${config.ImG}/cmsimg/${cms?.aboutfeko?.img}`} className="newmypro img-fluid" /> </>}
                            </div>
                        </div>
                    </div>
                    <div id="the_team"></div>
                </div>
                

                <div className="fm_the_team"  data-aos="fade-right">
                    <div className="container-fluid">
                    <h1 className="">{cms?.team?.question}
                        {/* The Team */}
                        </h1>
                    <p>{ReactHtmlParser(cms?.team?.answer)}</p>
                                    <p><span className="acko_yellow_txt">Acknowledgement: </span>Your consistent support is an asset to this team & has made a huge difference in our team. Thank you for being you, Thank you, The Freaky Nu.</p>
                        <div className="row team_change_marg">
                            <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                
                                <p className="team_evatxt">{ReactHtmlParser(eva)}</p>
                            </div>
                            <div className="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                                <div className="row">
                                    <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 card_one fm_trans"
                                        // style={first == SecondImage || first == ThirdImage ? {transform: `translate(${offset2}px)`} :
                                        // {transform: `translate(${offset1}px)`}} 
                                        id="first_card">
                                        <div className={trans1}
                                            onClick={() => {
                                                // setTimeout(() => {
                                                setFirst(second); setSecond(first); setthird(third); setFirstTitle(secondtitle);
                                                setSecondTitle(firsttitle); setthirdTitle(thirdtitle);
                                                setFirstsubTitle(secondsubtitle);
                                                setSecondsubTitle(firstsubtitle); setthirdsubTitle(thirdsubtitle);
                                                setMeunier(eva);setEva(meunier);setMiguel(miguel);
                                                // },1000);
                                                transit("1");
                                            }}
                                        >
                                            <img src={first}
                                            // src={`${config.ImG}${filters?.meunier?.content}/${filters.meunier?.img}`}
                                                className="img-fluid"
                                                alt="team"
                                                // className={transition}
                                                id={trans1}
                                            // onClick={() => {
                                            //     transit("1");
                                            // }}
                                            />
                                            <h6>{firsttitle}</h6>
                                            <p className="side_p">{firstsubtitle}</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 actve_card_st" id="second_card">
                                        <div
                                            className={trans2 + " img-prev"}
                                            onClick={() => {
                                                // setTimeout(() => {
                                                setFirst(first); setSecond(second); setthird(third);
                                                setFirstTitle(firsttitle);
                                                setSecondTitle(secondtitle); setthirdTitle(thirdtitle);
                                                setFirstsubTitle(firstsubtitle);
                                                setSecondsubTitle(secondsubtitle); setthirdsubTitle(thirdsubtitle);
                                                setMeunier(meunier);setEva(eva);setMiguel(miguel);
                                                // },1000);
                                            }}
                                        // onClick={() => setImage(2)}
                                        >
                                            <img src={second}
                                            // src={`${config.ImG}${filters?.eva?.content}/${filters?.eva?.img}`} 
                                            alt="team"
                                                // className="img-fluid fm_trans"
                                                className="img-fluid fm_trans"
                                            // style={second == ThirdImage || second == FirstImage ? {transform: `translate(${offset1}px)`} :
                                            // {transform: `translate(${offset2}px)`}
                                            />
                                            <h1>{secondtitle}</h1>
                                            <p className="mt-2 text-center">{secondsubtitle}</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 card_three" id="third_card"
                                    >
                                        <div className={trans3}
                                            onClick={() => {
                                                // setTimeout(() => {
                                                setFirst(first); setSecond(third); setthird(second);
                                                setFirstTitle(firsttitle);
                                                setSecondTitle(thirdtitle); setthirdTitle(secondtitle);
                                                setFirstsubTitle(firstsubtitle);
                                                setSecondsubTitle(thirdsubtitle); setthirdsubTitle(secondsubtitle);
                                                setMeunier(meunier);setEva(miguel);setMiguel(eva);
                                                
                                                //  },1000);
                                                transit2("3");
                                            }}
                                        // onClick={() => setImage(0)}
                                        >
                                            <img src={third}
                                            //  src={`${config.ImG}${filters?.Magnanimous?.content}/${filters?.Magnanimous?.img}`}
                                              alt="team"
                                                className="img-fluid fm_trans"
                                                // className={transition}
                                                id={trans3}
                                            // onClick={() => {
                                            //     transit2("3");
                                            // }} 
                                            // style={third == SecondImage || third == FirstImage ? {transform: `translate(${offset2}px)`} :
                                            // {transform: `translate(${offset3}px)`}}
                                            />
                                            <h6>{thirdtitle}</h6>
                                            <p className="side_p">{thirdsubtitle}</p>
                                        </div>
                                    </div>
                                    <div className="fm_slide_button" >
                                        <div className="d-flex align-items-center justify-content-between">
                                            <button className="btn btn-default"><img src={require("../assets/images/action-prev.png")} className="img-fluid"
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        setFirst(second); setSecond(first); setthird(third);
                                                        setFirstTitle(secondtitle);
                                                        setSecondTitle(firsttitle); setthirdTitle(thirdtitle);
                                                        setFirstsubTitle(secondsubtitle);
                                                setSecondsubTitle(firstsubtitle); setthirdsubTitle(thirdsubtitle);
                                                setMeunier(eva);setEva(meunier);setMiguel(miguel);
                                                        transit("1");
                                                    }, 300);
                                                }}


                                            /></button>
                                            <button className="btn btn-default" ><img src={require("../assets/images/action-next.png")} className="img-fluid"
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        setFirst(first); setSecond(third); setthird(second);
                                                        setFirstTitle(firsttitle);
                                                        setSecondTitle(thirdtitle); setthirdTitle(secondtitle);
                                                        setFirstsubTitle(firstsubtitle);
                                                setSecondsubTitle(thirdsubtitle); setthirdsubTitle(secondsubtitle);
                                                setMeunier(meunier);setEva(miguel);setMiguel(eva);
                                                        transit2("3");
                                                    }, 300);
                                                }}

                                            /></button>
                                        </div>
                                    </div>
                                </div>

                                {/* <Swiper
                            // install Swiper modules
                            slidesPerView={3}
                            breakpoints={{
                              0: {
                                slidesPerView: 1,
                              },
                              400:{
                                slidesPerView:1,
                              },
                              689: {
                                slidesPerView: 3,
                              },
                              865:{
                                slidesPerView:3
                              },
                              1000:{
                                slidesPerView:3
                              },
                              1500:{
                                slidesPerView:3
                              },
                              1700:{
                                slidesPerView:3
                              }
                            }}
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={20}
                            // slidesPerView={4}
                            navigation
                            loop="true"
                            autoplay="true"
                            centeredSlides="true"
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                            >
                                <SwiperSlide>
                                <div className="fm_slide">
                                    <img src={require("../assets/images/team.png")} className="img-fluid" />
                                    <h5>The Freaky Meunier</h5>
                                    <p>Mysterious Founder</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className="fm_slide">
                                    <img src={require("../assets/images/team.png")} className="img-fluid" />
                                    <h5>The Freaky Meunier</h5>
                                    <p>Mysterious Founder</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide><div className="fm_slide">
                                    <img src={require("../assets/images/team.png")} className="img-fluid" />
                                    <h5>The Freaky Meunier</h5>
                                    <p>Mysterious Founder</p>
                                </div></SwiperSlide>
                            <SwiperSlide><div className="fm_slide">
                                    <img src={require("../assets/images/team.png")} className="img-fluid" />
                                    <h5>The Freaky Meunier</h5>
                                    <p>Mysterious Founder</p>
                                </div></SwiperSlide>
                            <SwiperSlide>
                                <div className="fm_slide">
                                    <img src={require("../assets/images/team.png")} className="img-fluid" />
                                    <h5>The Freaky Meunier</h5>
                                    <p>Mysterious Founder</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className="fm_slide">
                                    <img src={require("../assets/images/team.png")} className="img-fluid" />
                                    <h5>The Freaky Meunier</h5>
                                    <p>Mysterious Founder</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide><div className="fm_slide">
                                    <img src={require("../assets/images/team.png")} className="img-fluid" />
                                    <h5>The Freaky Meunier</h5>
                                    <p>Mysterious Founder</p>
                                </div></SwiperSlide>
                            <SwiperSlide><div className="fm_slide">
                                    <img src={require("../assets/images/team.png")} className="img-fluid" />
                                    <h5>The Freaky Meunier</h5>
                                    <p>Mysterious Founder</p>
                                </div></SwiperSlide>
                            </Swiper> */}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="fm_slider">
                        <div className="fm_slider_one">
                            <div className="row">
                                <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-xs-12 fm_freaky_get">
                                    <div className="fm_freaky_content">
                                        <h1>Freaky Feko</h1>
                                        <p>Get to know the freaky feko</p>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-9 col-lg-9 col-md-8 col-xs-12">
                                    <OwlCarousel className='owl-theme' {...options1}>
                                        <div className="item">
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                       <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                        <h2>Embracing Inner Strength</h2>
                                                        <p>In the face of adversity, we discover our true strength. Feko reminds us that within each of us lies the power to overcome challenges and conquer our fears. Embrace your inner strength and let it guide you towards limitless possibilities.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class='item'>
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                     <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                    <h2>The Journey of Self-Discovery</h2>
                                                        <p>Sometimes, the most transformative journeys are the ones we take within ourselves. Feko invites you to embark on a journey of self-discovery, to embrace your uniqueness, and to find solace in your own company. Embrace the beauty of self-reflection and let it lead you to self-acceptance.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                   <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                    <h2>Breaking Barriers, Building Bridges</h2>
                                                        <p>"We are stronger when we come together, united by our differences. Feko celebrates the power of inclusivity and reminds us that by breaking down barriers, we build bridges of understanding and acceptance. Let's embrace diversity and create a world where everyone feels valued and included.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                     <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                    <h2>The Beauty of Imperfection</h2>
                                                        <p>In our imperfections lies our true beauty. Feko reminds us that it is through our cracks and flaws that our light shines brightest. Embrace your imperfections, for they make you unique and resilient. Let your authentic self shine and inspire others to do the same.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                    <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" />  
                                                    </div>
                                                    <div class="flip-box-back">
                                                    <h2>Beneath the Surface</h2>
                                                        <p>Appearances can be deceiving. Feko urges us to look beyond the surface, to see the depth and complexity within ourselves and others. Let empathy guide your interactions, and together, we can foster a culture of understanding, compassion, and genuine connection.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                     <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                    <h2>Unmasking Vulnerability</h2>
                                                        <p>True strength lies in embracing vulnerability. Feko encourages us to remove the masks we wear and embrace our authentic selves. By acknowledging and sharing our vulnerabilities, we create space for healing, empathy, and genuine connections that transcend societal expectations.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                  <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                    <h2>From Darkness to Light</h2>
                                                        <p>Even in the darkest of times, a single spark of hope can light our way. Feko reminds us that resilience and strength can emerge from our darkest moments. Let your light shine, and inspire others to find their own sources of inner strength and hope.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div className="item">
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                     <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" />  
                                                    </div>
                                                    <div class="flip-box-back">
                                                    <h2>The Power of a Listening Ear</h2>
                                                        <p>"Sometimes, all we need is someone who truly listens. Feko highlights the power of compassionate listening, offering support, and creating safe spaces for others to share their struggles. Let's cultivate empathy, lend a listening ear, and together, we can make a difference in someone's life.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                   <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div> 
                                                    <div class="flip-box-back">
                                                    <h2>The Ripple Effect of Kindness</h2>
                                                        <p>Kindness is a ripple that spreads far and wide, touching hearts and inspiring change. Feko encourages acts of kindness, big or small, as they have the power to create a positive ripple effect in our lives and the lives of others. Let's be the catalysts for kindness and create a world filled with compassion.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class='item'>
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front ">
                                                    <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                    <h2>Embracing the Journey to Mental Wellness</h2>
                                                        <p>Mental wellness is a journey we embark on with compassion and self-care. Feko emphasizes the importance of prioritizing mental health, practicing self-compassion, and seeking support when needed. Let's break the stigma, foster open conversations, and embrace a holistic approach to well-being.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                        <div className="fm_slider_two">
                            <div className="row">
                                <div className="col-12 col-xl-9 col-lg-9 col-md-8 col-xs-12">
                                    <OwlCarousel className='owl-theme' {...options2} rtlClass="owl-rtl" 
                                    rtl={true}
                                    >
                                        <div className="item">
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                     <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                    <h2>The Mirror's Reflection</h2>
                                                        <p>Feko encounters a magical mirror that shows people their true inner beauty. Through the mirror, he helps a young girl struggling with low self-confidence realize her unique qualities and embrace her self-worth. He highlights the importance of self-acceptance and recognizing one's inherent value.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class='item'>
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                    <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                        <h2>The Invisible Friend</h2>
                                                        <p>Feko befriends an invisible boy who feels isolated and excluded from society. With his humor and empathy, Feko helps the boy find his voice and encourages others to see the importance of inclusivity. He emphasizes the power of acceptance and the impact of genuine connections on mental well-being.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                     <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                        <h2>The Painted Masks</h2>
                                                        <p>Feko encounters a group of people wearing painted masks to hide their true emotions. Through his comedic antics, he encourages them to embrace vulnerability and express their feelings openly. He promotes the significance of authenticity and breaking free from societal expectations.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                   <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                        <h2>The Puzzle of Self-Discovery</h2>
                                                        <p>Feko stumbles upon a puzzled character searching for their identity. Feko helps them navigate through the pieces of their personality, teaching the importance of self-exploration, self-acceptance, and the journey toward finding one's true self.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                     <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                        <h2>The Rainbows in the Storm</h2>
                                                        <p>In a gloomy town, Feko spreads joy and positivity by painting colorful rainbows on rainy days. He highlights the power of optimism and resilience in the face of adversity, conveying the message that even in dark times, there is always hope and beauty to be found.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front ">
                                                   <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                        <h2>The Whispering Wind</h2>
                                                        <p>Feko encounters a lonely girl who feels unheard and struggles with her mental health. Through his whimsical 
storytelling, Feko teaches her the value of seeking support and opening up about her feelings. He promotes the importance of communication and seeking help when needed.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front ">
                                                     <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                        <h2>The Power of Kindness</h2>
                                                        <p>Feko witnesses acts of kindness that have a ripple effect on individuals' well-being and mental health. He inspires others to embrace compassion, showing how small gestures of kindness can create a more inclusive and supportive society.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front ">
                                                    <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                        <h2>The Fearful Shadow</h2>
                                                        <p>Feko encounters a character haunted by their fears and insecurities. Through a series of adventures, Feko helps them confront their fears, teaching the lesson that self-acceptance and facing challenges head-on can lead to personal growth and a sense of empowerment.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front ">
                                                    <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                        <h2>The Joyful Symphony</h2>
                                                        <p>Feko discovers a group of individuals who are passionate about music but have been discouraged by societal judgments. With his contagious enthusiasm, Feko encourages them to pursue their passion and express themselves through music, conveying the importance of finding joy and purpose in life.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div class="flip-box">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front ">
                                                        <img src={`${config.ImG}${filters?.question?.content}/${filters?.question?.img}`} className="img-fluid flip_front_img" /> 
                                                    </div>
                                                    <div class="flip-box-back">
                                                        <h2>The Garden of Emotions</h2>
                                                        <p>Feko tends to a neglected garden representing people's emotions. As he nurtures the different emotions, he teaches the importance of acknowledging and embracing the full spectrum of emotions, emphasizing that mental well-being involves tending to both the positive and challenging emotions we experience.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                       
                                        
                                    </OwlCarousel>
                                </div>
                                <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-xs-12 fm_header_bottom">
                                    <ul className="d-flex fm_header_button">
                                        {/* <li>
                                            <Link to="/community"><button className="btn btn-warning primary">
                                                <span>View More</span>
                                            </button>
                                            </Link>
                                        </li> */}
                                        {/* <li className="me-3">
                <button className="btn btn-warning primary"  data-bs-toggle="modal" data-bs-target="#myModal">
                    <span>Connect Wallet</span>
                    </button>
            </li> */}

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fm_objective_top" data-aos="fade-up">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <div className="freaky">
                                    {console.log("IMGGGGGG",cms?.thefreakyfekoobjective)}
                                    <img src={`${config.ImG}/cmsimg/${cms?.thefreakyfekoobjective?.img[1]}`} className="img-fluid newmypro" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 feko mob_hide">
                                <img src={require("../assets/images/feko.png")} className="img-fluid" />
                            </div>
                            <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12 fm_objective pe-0">
                                <div className="fm_objectives">
                                    <h1>The Freaky Feko Objective</h1>
                                    <div className="fm_slide fm_scroll">
                                        <p>{ReactHtmlParser(cms?.thefreakyfekoobjective?.answer)}
                                        </p>
                                    </div>
                                    <div className="fm_buttons mt-5 d-block">
                                        <button className="btn scroll-up-button">
                                            <img src={require("../assets/images/up-arrow.png")} className="img-fluid scrl_img_opacity" />
                                        </button>
                                        <button className="btn scroll-down-button">
                                            <img src={require("../assets/images/down-arrow.png")} className="img-fluid scrl_img_opacity" />
                                        </button>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12 fm_obj ps-1">
                                <img src={require("../assets/images/objective.png")} className="img-fluid" data-aos="fade-up" />
                                <img src={`${config.ImG}/cmsimg/${cms?.thefreakyfekoobjective?.img[0]}`} className="img-fluid" data-aos="zoom-in" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fm_freakey_mission" data-aos="fade-right">
                    <div className="container-fluid">
                        <div className="fm_mission">
                            <div className="row">
                                <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 mission_img">
                                    <img src={`${config.ImG}/cmsimg/${cms?.thefreakyfekomission?.img}`} className="img-fluid" />
                                </div>
                                <div className="col-12 col-xl-8 col-lg-8 col-md-6 col-sm-6 col-xs-12">
                                    <div className="content home_feko_mission">
                                        <h1>The Freaky Feko Mission </h1>
                                        <div class="fm_mission_scroll">
                                            <p>{ReactHtmlParser(cms?.thefreakyfekomission?.answer)}</p>
                                        </div>
                                    </div>
                                    <div className="fm_mission_image text-right">
                                        <img src={require("../assets/images/mission-title.png")} className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fm_freakey_mission fm_freakey_value" data-aos="fade-left">
                    <div className="container-fluid">
                        <div className="fm_mission">
                            <div className="row">
                                <div className="col-12 col-xl-8 col-lg-8 col-md-6 col-sm-6 col-xs-12">
                                    <div className="content">
                                        <h1>The Freaky Feko Value </h1>
                                        <div class="fm_mission_scroll">
                                            <p>{ReactHtmlParser(cms?.thefreakyfekovalue?.answer)}</p>
                                        </div>
                                        <div className="value_relative">
                                        <img className="" src={require('../assets/images/VALUE1.png')}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 mission_one_img">
                                    <img src={`${config.ImG}/cmsimg/${cms?.thefreakyfekovalue?.img}`} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fm_action_plan" data-aos="fade-left">
                    <div className="container-fluid">
                        <h1>Action Plan</h1>
                        <p className="freaky_feko">
                            Freaky Feko plans to create a safe space for self-expression through a variety of initiatives and platforms.

                        </p>
                        {console.log("CMSSSS",cms?.digitalplatforms1)}
                        <p className="fm_by_providing">By providing a welcoming and supportive environment, Feko hopes to empower people to express themselves and connect with others. </p>
                        <p    id="roadmap_link" className="fm_by_providing">Here are some ways that Feko plans to achieve this:</p>
                        {cms?.digitalplatforms1 && <OwlCarousel  className='owl-theme' {...options}>
                            <div class='item'>
                                <div className="fm_plan">
                                    <img src={`${config.ImG}cmsimg/${cms?.digitalplatforms1?.img}`} className="img-fluid" data-aos="zoom-in" />
                                    <h5>{cms?.digitalplatforms1?.question}</h5>
                                    <p>
                                        {ReactHtmlParser(cms?.digitalplatforms1?.answer)}
                                        {/* Freaky Feko plans to create digital platforms such as apps, games, and social media channels that encourage self-expression and provide a safe space for people to share their thoughts and ideas.
                                        Freaky Feko plans to create a safe space for self-expression through a variety of initiatives and platforms. */}
                                    </p>
                                </div>
                            </div>
                            <div class='item'>
                                <div className="fm_plan">
                                    <img src={`${config.ImG}cmsimg/${cms?.digitalplatforms2?.img}`} className="img-fluid" data-aos="zoom-in" />
                                    <h5>{cms?.digitalplatforms2?.question}</h5>
                                    <p> {ReactHtmlParser(cms?.digitalplatforms2?.answer)}
                                        {/* Freaky Feko plans to create digital platforms such as apps, games, and social media channels that encourage self-expression and provide a safe space for people to share their thoughts and ideas.
                                        Freaky Feko plans to create a safe space for self-expression through a variety of initiatives and platforms. */}
                                    </p>
                                </div>
                            </div>
                            <div class='item'>
                                <div className="fm_plan">
                                    <img src={`${config.ImG}cmsimg/${cms?.digitalplatforms3?.img}`} className="img-fluid" data-aos="zoom-in" />
                                    <h5>{cms?.digitalplatforms3?.question}</h5>
                                    <p> {ReactHtmlParser(cms?.digitalplatforms3?.answer)}
                                        {/* Freaky Feko plans to create digital platforms such as apps, games, and social media channels that encourage self-expression and provide a safe space for people to share their thoughts and ideas.
                                        Freaky Feko plans to create a safe space for self-expression through a variety of initiatives and platforms. */}
                                    </p>
                                </div>
                            </div>
                            <div class='item'>
                                <div className="fm_plan">
                                    <img src={`${config.ImG}cmsimg/${cms?.digitalplatforms4?.img}`} className="img-fluid" data-aos="zoom-in" />
                                    <h5>{cms?.digitalplatforms4?.question}</h5>
                                    <p> {ReactHtmlParser(cms?.digitalplatforms4?.answer)}
                                        {/* Freaky Feko plans to create digital platforms such as apps, games, and social media channels that encourage self-expression and provide a safe space for people to share their thoughts and ideas.
                                        Freaky Feko plans to create a safe space for self-expression through a variety of initiatives and platforms. */}
                                    </p>
                                </div>
                            </div>
                            <div class='item'>
                                <div className="fm_plan">
                                    <img src={`${config.ImG}cmsimg/${cms?.digitalplatforms5?.img}`} className="img-fluid" data-aos="zoom-in" />
                                    <h5 >{cms?.digitalplatforms5?.question}</h5>
                                   
                                    <p > {ReactHtmlParser(cms?.digitalplatforms5?.answer)}
                                        {/* Freaky Feko plans to create digital platforms such as apps, games, and social media channels that encourage self-expression and provide a safe space for people to share their thoughts and ideas.
                                        Freaky Feko plans to create a safe space for self-expression through a variety of initiatives and platforms. */}
                                    </p>
                                </div>
                            </div>
                            {/* <div class='item'>
                    <div className="fm_plan">
                            <img src={require("../assets/images/platform.png")} className="img-fluid"  data-aos="zoom-in"/>
                            <h5>Digital Platforms</h5>
                            <p>Freaky Feko plans to create digital platforms such as apps, games, and social media channels that encourage self-expression and provide a safe space for people to share their thoughts and ideas.
                            Freaky Feko plans to create a safe space for self-expression through a variety of initiatives and platforms.
                            </p>
                        </div>
                    </div>
                    <div class='item'>
                    <div className="fm_plan">
                            <img src={require("../assets/images/platform.png")} className="img-fluid"  data-aos="zoom-in"/>
                            <h5>Digital Platforms</h5>
                            <p>Freaky Feko plans to create digital platforms such as apps, games, and social media channels that encourage self-expression and provide a safe space for people to share their thoughts and ideas.Freaky Feko plans to create a safe space for self-expression through a variety of initiatives and platforms.</p>
                        </div>
                    </div>
                    <div class='item'>
                    <div className="fm_plan">
                            <img src={require("../assets/images/platform.png")} className="img-fluid"  data-aos="zoom-in"/>
                            <h5>Digital Platforms</h5>
                            <p>Freaky Feko plans to create digital platforms such as apps, games, and social media channels that encourage self-expression and provide a safe space for people to share their thoughts and ideas.
                            Freaky Feko plans to create a safe space for self-expression through a variety of initiatives and platforms.
                            </p>
                        </div>
                    </div>
                    <div class='item'>
                    <div className="fm_plan">
                            <img src={require("../assets/images/platform.png")} className="img-fluid"  data-aos="zoom-in"/>
                            <h5>Digital Platforms</h5>
                            <p>Freaky Feko plans to create digital platforms such as apps, games, and social media channels that encourage self-expression and provide a safe space for people to share their thoughts and ideas.
                            Freaky Feko plans to create a safe space for self-expression through a variety of initiatives and platforms.
                            </p>
                        </div>
                    </div>
                    <div class='item'>
                    <div className="fm_plan">
                            <img src={require("../assets/images/platform.png")} className="img-fluid"  data-aos="zoom-in"/>
                            <h5>Digital Platforms</h5>
                            <p>Freaky Feko plans to create digital platforms such as apps, games, and social media channels that encourage self-expression and provide a safe space for people to share their thoughts and ideas.
                            Freaky Feko plans to create a safe space for self-expression through a variety of initiatives and platforms.
                            </p>
                        </div>
                    </div>
                    <div class='item'>
                    <div className="fm_plan">
                            <img src={require("../assets/images/platform.png")} className="img-fluid"  data-aos="zoom-in"/>
                            <h5>Digital Platforms</h5>
                            <p>Freaky Feko plans to create digital platforms such as apps, games, and social media channels that encourage self-expression and provide a safe space for people to share their thoughts and ideas.
                            Freaky Feko plans to create a safe space for self-expression through a variety of initiatives and platforms.
                            </p>
                        </div>
                    </div>
                    <div class='item'>
                    <div className="fm_plan">
                            <img src={require("../assets/images/platform.png")} className="img-fluid"  data-aos="zoom-in"/>
                            <h5>Digital Platforms</h5>
                            <p>Freaky Feko plans to create digital platforms such as apps, games, and social media channels that encourage self-expression and provide a safe space for people to share their thoughts and ideas.
                            Freaky Feko plans to create a safe space for self-expression through a variety of initiatives and platforms.
                            </p>
                        </div>
                    </div> */}
                        </OwlCarousel>}
                    </div>
                    
                </div>
                
               
                {/* <div id="roadmap"></div> */}
                {/* <div className="fm_acknowledgement">
                    <p>Acknowledgment: <span className="fm_acknow_span">Your consistent support is an asset to this team & has made a huge difference in our team. Thank you for being you, Thank you, The Freaky Nu.</span></p>
                </div> */}
                <div className="fm_roadmap_activations" >
                    <h1>Roadmap Activations</h1>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 fm_roadmap mob_hide" data-aos="fade-left">
                                <img src={require("../assets/images/roadmap.png")} className="img-fluid" />
                            </div>
                            <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 fm_table" data-aos="fade-left">
                                <div className="fm_roadmap_Activations w-75 m-auto">
                                    <div className="table_responsive">
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p >Phase</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p className="fm_center_txt" style={{ fontWeight: "bold" }}>Item</p>
                                            </div>
                                        </div>
                                        <div className="fm_layers_bg">
                                            <p>Q1</p>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>1.0</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>Brand Establishment & Pre-Launch Marketing</p>
                                            </div>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>1.1</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>Minting Begins</p>
                                            </div>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>1.2</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>Twitter Space</p>
                                            </div>
                                        </div>
                                        <div className="fm_layers_bg">
                                            <p>Q2</p>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>2.0</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>Merch Designs Online Store</p>
                                            </div>

                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>2.1</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>Exclusive Customization Online Store</p>
                                            </div>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>2.2</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>Giveaway & Airdrop ERC 721 For the First 500 Minters</p>
                                            </div>
                                        </div>
                                        
                                        <div className="fm_layers_bg">
                                            <p>Q3</p>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>3.0</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>TFS Membership</p>
                                            </div>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>3.1</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>Exclusive Customization of NFT Collectibles</p>
                                            </div>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>3.2</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>TFS Entertainment Mobile Apps</p>
                                            </div>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>3.3</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>TFS Foundation</p>
                                            </div>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>3.4</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>Mental Health Charity Donations</p>
                                            </div>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>3.5</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>Exclusive Metaverse Event</p>
                                            </div>
                                        </div>
                                        

                                        
                                        <div className="fm_layers_bg">
                                            <p>Q4</p>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>4.0</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>P2E Mobile App</p>
                                            </div>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>4.1</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>Collaborations</p>
                                            </div>
                                        </div>
                                        {/* <div className="fm_layers_bg">
                                            <p>Q5</p>
                                        </div> */}
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>4.2</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>DAO</p>
                                            </div>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>4.3</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>Token</p>
                                            </div>
                                        </div>
                                        
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>4.4</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>Special Offers Experiences</p>
                                            </div>
                                        </div>
                                        <div className="fm_layers_bg">
                                            <p>Community Integration</p>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>5.0</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>Release FF Community Roadmap 2.0</p>
                                            </div>
                                        </div>
                                        <div className="fm_layers">
                                            <div className="fm_layers_no">
                                                <p>5.1</p>
                                            </div>
                                            <div className="fm_layer_background">
                                                <p>Integrate Community Ideas Into Next The Freaky Studios Project</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-1 col-lg-1 col-md-1 col-sm-12 col-xs-12 fm_circle" data-aos="fade-left">
                                {/* <img src={require("../assets/images/big-circle.png")} className="img-fluid" /> */}
                            </div>
                            {/* <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 fm_t_shirt" data-aos="zoom-in">
                        <img src={require("../assets/images/t-shirt.png")} className="img-fluid" />
                    </div> */}
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            
                       

                {/* <div className="fm_minting_info" data-aos="zoom-in">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h1>Minting</h1>
                                <ul className="d-flex fm_header_button justify-content-center mt-5">
                                    <li>
                                        <a href={url} target="balnk"> <button className="btn btn-warning secondary">
                                            <span>Mint</span>
                                        </button></a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <ul>
                                <li>a.Connect Wallet to mint</li>
                                <li>b.(URL will be shared)</li>
                                <li>c.Buy and view the collection</li>
                            </ul>
                           
                        </div>
                        </div>
                    </div>
                </div> */}
                <div className="fm_layers_count" data-aos="zoom-in" >
                    <h1 className="fm_laycnt">Layers Count</h1>
                    <p className="fm_layers_para">We have 10,000 items in layers count and possible items are Three trillion seven hundred twenty billion two hundred
                        seventeen million and two hundred forty-eight thousand.</p>
                    <div className="container-fluid">
                        <div className="fm_layers_count_table">
                            <div className="table_responsive">
                                <div className="fm_layers_item_count">
                                    <p>10,000 times</p>
                                </div>

                                <div className="fm_layers">
                                    <div className="fm_layers_no">
                                        <p style={{ fontWeight: "bold" }}>No.</p>
                                    </div>
                                    <div className="fm_layer_background fm_total_layers_center">
                                        <p style={{ fontWeight: "bold" }}>Layers Name</p>
                                    </div>
                                    <div className="fm_layer_treats">
                                        <p style={{ fontWeight: "bold" }}>Number of traits</p>
                                    </div>
                                </div>

                                <div className="fm_layers">
                                    <div className="fm_layers_no">
                                        <p>1</p>
                                    </div>
                                    <div className="fm_layer_background">
                                        <p>Background</p>
                                    </div>
                                    <div className="fm_layer_treats">
                                        <p>15 traits</p>
                                    </div>
                                </div>
                                <div className="fm_layers">
                                    <div className="fm_layers_no">
                                        <p>2</p>
                                    </div>
                                    <div className="fm_layer_background">
                                        <p>Feko's Face</p>
                                    </div>
                                    <div className="fm_layer_treats">
                                        <p>352 traits</p>
                                    </div>
                                </div>

                                <div className="fm_layers">
                                    <div className="fm_layers_no">
                                        <p>3</p>
                                    </div>
                                    <div className="fm_layer_background">
                                        <p>Outfit</p>
                                    </div>
                                    <div className="fm_layer_treats">
                                        <p>184 traits</p>
                                    </div>
                                </div>

                                <div className="fm_layers">
                                    <div className="fm_layers_no">
                                        <p>4</p>
                                    </div>
                                    <div className="fm_layer_background">
                                        <p>Earnings</p>
                                    </div>
                                    <div className="fm_layer_treats">
                                        <p>183 traits</p>
                                    </div>
                                </div>
                                <div className="fm_layers">
                                    <div className="fm_layers_no">
                                        <p>5</p>
                                    </div>
                                    <div className="fm_layer_background">
                                        <p>Eye Wear</p>
                                    </div>
                                    <div className="fm_layer_treats">
                                        <p>150 traits</p>
                                    </div>
                                </div>

                                <div className="fm_layers">
                                    <div className="fm_layers_no">
                                        <p>6</p>
                                    </div>
                                    <div className="fm_layer_background">
                                        <p>Head style</p>
                                    </div>
                                    <div className="fm_layer_treats">
                                        <p>137 traits</p>
                                    </div>
                                </div>

                                <div className="fm_total_layers">

                                    <div className="fm_total_layers_cat">
                                        <p>Total Layers</p>
                                    </div>
                                    <div className="fm_total_layers_no">
                                        <p><b>1.021 traits</b></p>
                                    </div>
                                </div>

                                <div className="fm_total_layers" >
                                    <div className="fm_total_layers_cat fm_total_layers_center">
                                        <p style={{ paddingBottom: 0 }}>Possible Items</p>
                                        <p style={{ paddingTop: 0 }}>(<b>Three trillion</b> six hundred fify-three billion five hundred fourty-six million and six hundred eighty-eight thousand.)</p>
                                    </div>
                                    <div className="fm_total_layers_no">
                                        <p><b>3.653.546.688.000 Items</b></p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mint_man_bt">  
        <div className="relative_div">
                        <img className="img-fluid cross_stand" src={`${config.ImG}${filters?.mint_banner?.content}/${filters?.mint_banner?.img}`}/>

                        {/* <ul className="d-flex fm_header_button abs_btn_mint">
                            <li className="">
                                <button className="btn btn-warning primary">
                                    <span>Announcement</span>
                                </button>
                            </li>
                            </ul> */}

                            <ul className="d-flex fm_header_button abs_btn_mint">
                                    <li>
                                        <a href={url} target="balnk"> <button className="btn btn-warning primary">
                                            <span>Mint</span>
                                        </button></a>
                                    </li>
                                </ul>
                        
                        </div>
                        </div> 
                        

                {/* <div className="fm_rankings"  data-aos="fade-up">
                <div className="container-fluid">
                    <h1>Rankings</h1>
                    <div className="row">
                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 fm_ranking" data-aos="zoom-in">
                            <div className="fm_rankings_dynamic">
                                <div className="fm_rankings_cover">
                                    <img src={require("../assets/images/timer.png")} className="img-fluid" />
                                    <div className="fm_rankings_profile">
                                    <img src={require("../assets/images/timer.png")} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="fm_rankings_content">
                                    <h4>Lorem Ipsum</h4>
                                    <p>It is a long established fact that a reader will be distracted by the readable content</p>
                                </div>
                                <div className="fm_rankings_lorem">
                                    <ul className="d-flex align-items-center justify-content-between">
                                        <li>
                                            <h5>2,245</h5>
                                            <p>Lorem</p>
                                        </li>
                                        <li>
                                            <h5>2,245</h5>
                                            <p>Lorem</p>
                                        </li>
                                        <li>
                                            <h5>2,245</h5>
                                            <p>Lorem</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 fm_ranking" data-aos="zoom-in"> 
                        <div className="fm_rankings_dynamic">
                                <div className="fm_rankings_cover">
                                    <img src={require("../assets/images/timer.png")} className="img-fluid" />
                                    <div className="fm_rankings_profile">
                                    <img src={require("../assets/images/timer.png")} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="fm_rankings_content">
                                    <h4>Lorem Ipsum</h4>
                                    <p>It is a long established fact that a reader will be distracted by the readable content</p>
                                </div>
                                <div className="fm_rankings_lorem">
                                    <ul className="d-flex align-items-center justify-content-between">
                                        <li>
                                            <h5>2,245</h5>
                                            <p>Lorem</p>
                                        </li>
                                        <li>
                                            <h5>2,245</h5>
                                            <p>Lorem</p>
                                        </li>
                                        <li>
                                            <h5>2,245</h5>
                                            <p>Lorem</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 fm_ranking" data-aos="zoom-in">
                        <div className="fm_rankings_dynamic">
                                <div className="fm_rankings_cover">
                                    <img src={require("../assets/images/timer.png")} className="img-fluid" />
                                    <div className="fm_rankings_profile">
                                    <img src={require("../assets/images/timer.png")} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="fm_rankings_content">
                                    <h4>Lorem Ipsum</h4>
                                    <p>It is a long established fact that a reader will be distracted by the readable content</p>
                                </div>
                                <div className="fm_rankings_lorem">
                                    <ul className="d-flex align-items-center justify-content-between">
                                        <li>
                                            <h5>2,245</h5>
                                            <p>Lorem</p>
                                        </li>
                                        <li>
                                            <h5>2,245</h5>
                                            <p>Lorem</p>
                                        </li>
                                        <li>
                                            <h5>2,245</h5>
                                            <p>Lorem</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div></div> */}

                <div className="fm_big_events" data-aos="fade-up" >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 fm
                        _big" data-aos="fade-right">
                                <div className="fm_line_up" data-aos="fade-right" data-aos-offset="200" data-aos-delay="1000">
                                    <img src={require("../../src/assets/images/line-up.png")} className="img-fluid" data-aos-offset="200" data-aos-delay="500" />
                                </div>
                                <div className="main_image">
                                    <div className="quotation_one" data-aos="zoom-in" data-aos-offset="200" data-aos-delay="1500">
                                        <img src={require("../../src/assets/images/quotation1.png")} className="img-fluid" />
                                    </div>
                                    <img src={require("../../src/assets/images/big-events.png")} className="img-fluid" />
                                    <div className="quotation_two" data-aos="zoom-in" data-aos-offset="200" data-aos-delay="1500">
                                        <img src={require("../../src/assets/images/quotation2.png")} className="img-fluid" data-aos-offset="200" data-aos-delay="500" />
                                    </div>
                                </div>
                                <div className="fm_line_down" id='event_navi' data-aos="fade-left" data-aos-offset="200" data-aos-delay="1000">
                                    <img src={require("../../src/assets/images/line-down.png")} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 fm_count_down" data-aos="fade-left">
                                <div className="fm_count_down_starts">

                                    {/* <h1>{events && events[0]?.heading}</h1>
                                    <p>{events && events[0]?.content}</p>
                                    <h2>Event Starts In</h2> */}
                                    {events.length !== 0 ?
                                        <>
                                            <h1>{events && events[0]?.heading}</h1>
                                            <p>{events && ReactHtmlParser(events[0]?.content.slice(0,20))}</p>
                                            <h2>Event Starts In</h2>  <Countdown date={(new Date(events[0]?.date).getTime())} renderer={renderer} className="countdown_grey" />
                                        </> :
                                        <>
                                            <h1 id="events">Count Down</h1>
                                            {/* <p>No Events are conducted by the admin at still</p> */}
                                            <h2>Event Starts In</h2>
                                            <ul className="d-flex align-items-center justify-content-between p-0 fm_count">

                                                <li>
                                                    <p>Days</p>
                                                    <h1>0</h1>
                                                </li>
                                                <li>
                                                    <p>Hours</p>
                                                    <h1>0</h1>
                                                </li>
                                                <li>
                                                    <p>Minutes</p>
                                                    <h1>0</h1>
                                                </li>
                                                <li>
                                                    <p>Second</p>
                                                    <h1>0</h1>
                                                </li>
                                            </ul>
                                        </>
                                    }
                                    {/* {console.log("evetnssss",new Date(events[0]?.date).getTime(),"jj",Date.now() +  (new Date(events[0]?.date).getTime()))} */}

                                    {/* <ul className="d-flex align-items-center justify-content-between p-0 fm_count">

                                        <li>
                                            <p>Days</p>
                                            <h1>{days}</h1>
                                        </li>
                                        <li>
                                            <p>Hours</p>
                                            <h1>{hour}</h1>
                                        </li>
                                        <li>
                                            <p>Minutes</p>
                                            <h1>{minute}</h1>
                                        </li>
                                        <li>
                                            <p>Second</p>
                                            <h1>{sec}</h1>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="fm_big_event mt-5">
                    <div className="fm_content" data-aos="fade-left">
                        <h1>Big Event</h1>
                        {/* <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                        <ul className="d-flex fm_header_button align-items-center justify-content-center">
                            <li className="me-3">
                                <button className="btn btn-warning primary">
                                    <span>Announcement</span>
                                </button>
                            </li>
                            <li className="me-3">
                                <button className="btn btn-warning secondary">
                                    <span>Gallery</span>
                                </button>
                            </li>
                            <li>
                                <button className="btn btn-warning primary">
                                    <span>Book a Ticket</span>
                                </button>
                            </li>

                        </ul>
                    </div>

                </div>

                <div className="fm_sneak_peek" data-aos="fade-up">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12" data-aos="fade-right">
                                <h1 className="text-left">Sneak Peek</h1>
                                <div className="content">
                                    {/* <p>Get an exclusive glimpse into the exciting world of The Freaky Feko. Here, we offer a behind-the-scenes look at our
upcoming projects, artworks, and collaborations. Discover never-before-seen concept art, watch sneak preview
clips from our animated series, and explore the creative process that brings The Freaky Feko to life. Our Sneak
Peek Section is designed to give you a taste of what's to come and keep you eagerly anticipating our latest
releases. Stay ahead of the curve and be the first to witness our innovative ideas and captivating storytelling.
Whether you're a devoted fan or a curious newcomer, we will offer a thrilling glimpse into the wild and wonderful
world of The Freaky Feko. Don't miss out on this exclusive opportunity to delve deeper into our universe and get a
sneak peek at the magic that awaits you. Stay tuned for regular updates and sneak peeks that will leave you
craving more. Join us on this exhilarating journey and be part of The Freaky Feko experience like never before!</p> */}
<p>{ReactHtmlParser(cms?.sneakpeek?.answer)}</p>
                                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}

                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12" data-aos="fade-left">
                                <img src={`${config.ImG}/cmsimg/${cms?.sneakpeek?.img}`} className="img-fluid sneak_peak_img" />
                            </div>
                        </div>
                    </div>
                </div>








                {/* <div className="fm_freaky_comming_soon"  data-aos="fade-up">
                 <h1>My Freaky Feko</h1>
                 <p>A place to customize your own art with FF digital assets and wearables.</p>
                 <div className="fm_freaky_comming_soon_image">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="fm_image_1" data-aos="fade-right">
                    <img src={require("../assets/images/line1.png")} className="img-fluid"/>
                    </div>
                    <div className="fm_image_2" data-aos="fade-left">
                    <img src={require("../assets/images/line2.png")} className="img-fluid"/>
                    </div>
                  </div>
                  <h1>Coming Soon</h1>
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="fm_image_1" data-aos="fade-right">
                    <img src={require("../assets/images/line1.png")} className="img-fluid"/>
                    </div>
                    <div className="fm_image_2" data-aos="fade-left">
                    <img src={require("../assets/images/line2.png")} className="img-fluid"/>
                    </div>
                  </div>
                </div>   
            </div> */}
                <div className="fm_latest_news" data-aos="fade-up" data-a>
                    <div className="container-fluid">
                        <h1 className="fm_latest_news_heading">Latest News</h1>
                        <div className="row">

                            {article?.map((item, index) => {

                                if (index === 0) {
                                    { console.log("article", index) }
                                    return (
                                        <>
                                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 fm_latest_news_changes">
                                                <div className="fm_latest_news_dynamic fm_polygon">
                                                    <div className="fm_content">
                                                        <h1>{index + 1}</h1>
                                                        <p className="fm_time">August 16, 2021/ Minting/ 6 Comments</p>
                                                        <p className="fm_sub_time">{item.heading}</p>
                                                        {/* <img src={require("../../src/assets/images/latest-news1.png")} className="img-fluid" /> */}
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="fm_img_readmore width_full">
                                                            <NavLink smooth to=""><img src={`${config.ImG}${item.img}`} className="img-fluid latest_new_img" /></NavLink>
                                                        </div>
                                                        {/* <div className="fm_news">
                                       <img src={require("../../src/assets/images/news.png")} className="img-fluid" />
                                       </div> */}
                                                    </div>
                                                </div>
                                                <div className="fm_read_more_article mt-2">
                                                    <NavLink smooth to="/blog"><button className="btn btn-warning">Read More Article</button></NavLink>
                                                </div>
                                            </div>
                                        </>
                                    )


                                }
                            }
                            )}

                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 fm_latest_news_static">
                                {article?.map((item, index) => {
                                    if (index > 0 && index < 4) {
                                        if (index === 1) {
                                            return (
                                                <>
                                                    <div className="fm_latest_news_dynamic">
                                                        <div className="fm_content">
                                                            <h1>{index + 1}</h1>
                                                            {/* <p className="fm_time">August 16, 2021/ Minting/ 6 Comments</p> */}
                                                            <p className="fm_sub_time">{item.heading}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between">

                                                            <p className="text-white">{ReactHtmlParser(item.content.slice(0, 20))} </p>

                                                            <NavLink smooth to="/blog"><div className="fm_img_readmore">
                                                                {/* <img src={`http://localhost:8080/${item.img}`} className="img-fluid" /> */}
                                                                <img src={require("../../src/assets/images/read-more.png")} className="img-fluid" />
                                                            </div></NavLink>
                                                        </div>
                                                    </div>
                                                </>
                                            )

                                        }
                                        else {
                                            return (
                                                <>
                                                    <div className="fm_latest_news_dynamic mt-2">
                                                        <div className="fm_content">
                                                            <h1>{index + 1}</h1>
                                                            {/* <p className="fm_time">August 16, 2021/ Minting/ 6 Comments</p> */}
                                                            <p className="fm_sub_time">{item.heading}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between">

                                                            <p className="text-white">{ReactHtmlParser(item.content.slice(0, 20))} </p>


                                                            <NavLink smooth to="/blog"><div className="fm_img_readmore">
                                                                <img src={require("../../src/assets/images/read-more.png")} className="img-fluid" />
                                                            </div></NavLink>
                                                        </div>
                                                    </div>
                                                </>
                                            )

                                        }
                                    }

                                })}
                                {/* <div className="fm_latest_news_dynamic mt-2">
                          <div className="fm_content">
                           <h1>4</h1>
                           <p className="fm_time">August 16, 2021/ Minting/ 6 Comments</p>
                           <p className="fm_sub_time">August 16, 2021/ Minting/ 6 Comments</p>
                          </div> 
                          <div className="d-flex align-items-center justify-content-between">
                             <div className="fm_img_readmore">
                                <img src={require("../../src/assets/images/read-more.png")} className="img-fluid" />
                             </div>
                          </div>      
                        </div> */}
                                
                            </div>
                            <div className="fm_were_team mt-3">
                                    <p>Stay up to date with the latest happenings in the world of Freaky Feko!</p>
                                </div>
                        </div>
                    </div>
                </div>

                <div className="fm_contact" data-aos="zoom-in">
                    <div className="fm_phone">
                        <div className="container-fluid">
                            <div className="text-left fm_phone_width">
                                <h1>We Would Like to hear from you</h1>
                                <div className="fm_contact_form">
                                    <div className="row mt-4">
                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 fm_contact_form_name">
                                            <label className="pb-4">Name</label>
                                            <input type="text"
                                                onChange={(e) => setMsgname(e.target.value)}
                                                value={msgname}
                                                id="name" className="form-control" placeholder="Name" />
                                            <span className="error_txt_clr">{error?.name}</span>
                                        </div>
                                        {console.log("referencwe", msgname)}
                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 fm_contact_form_email">
                                            <label className="pb-4">Email</label>
                                            <input type="email" onChange={(e) => setMsgemail(e.target.value)} id="email" value={msgemail} className="form-control" placeholder="Email" />
                                            <span className="error_txt_clr">{error?.email}</span>
                                        </div>
                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12  fm_contact_form_subject">
                                            <label className="pb-4">Subject</label>
                                            <input type="email" onChange={(e) => setMsgsubject(e.target.value)} id="subject" value={msgsubject} className="form-control" placeholder="Subject" />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 fm_contact_form_message">
                                            <label className="pb-4">Message</label>
                                            <textarea rows="4" onChange={(e) => setmsg(e.target.value)} id="message" value={msg} cols="50" placeholder="Message" className="form-control"></textarea>
                                        </div>
                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 fm_contact_form_submit">
                                        {/* <ul className="d-flex justify-content-center fm_header_button fm_header_btn_prov">
                                            <li className="me-0">
                                                {msgname != '' && msgemail != '' ? <button onClick={() => commmentonsubmithandler()}  data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="btn btn-warning primary">
                                                        <span>Submit</span>
                                                    </button> :
                                                    <button  className="btn btn-warning primary">
                                                    <span>Submit</span>
                                                </button>}
                                                </li>
                                              
                                            </ul> */}

                                            <ul className="d-flex fm_header_button">
                
                                            <li className="me-0">
                                                {msgname != '' && msgemail != '' ? <button onClick={() => commmentonsubmithandler()}  data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="btn btn-warning primary">
                                                        <span>Submit</span>
                                                    </button> :
                                                    <button  className="btn btn-warning primary w_86">
                                                    <span>Submit</span>
                                                </button>}
                                                </li>
                            </ul>


                                           
                                            

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fm_verified py-5" data-aos="fade-up">
                    <div className="flexing_prove">
                    <p>VERIFIED SMART CONTRACT ADDRESS: <span>{config.Contractaddress} - </span> 
                    {/* -<Link className="link_white" to='/record'>Provenance Record</Link>  */}
                    </p>


                    
                                            <ul className="d-flex justify-content-center fm_header_button fm_header_btn_prov">
                                                <li className="me-0">
                                                    <button onClick={() => commmentonsubmithandler()} 

                                                    //  data-bs-toggle="modal" data-bs-target="#staticBackdrop" 
                                                     
                                                     className="btn btn-warning primary">
                                                    <NavLink exact to="/record"><span>Provenance Record</span></NavLink>
                                                        
                                                    </button>
                                                </li>
                                                {/* <li>
                                    <button className="btn btn-warning secondary">
                                        <span>Shop</span>
                                        </button>
                                </li> */}
                                            </ul>
                                            </div>
                                        
                </div>

            </div>




            {/* confirm modal */}

        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title text-center w-100 fs-5" id="staticBackdropLabel">The Freaky Studios</h1>
        {/* <button type="button"  class="btn-close modal_cls_btn" data-bs-dismiss="modal" aria-label="Close"></button> */}
        <i class="bi bi-x modal_cls_btn" data-bs-dismiss="modal" aria-label="Close"/>
      </div>
      <div class="modal-body">
      {/* you added in the community */}
      {/* {modelmsg} */}
      <p>Thank you for reaching out to Freaky Feko! We appreciate your interest and taking the time to contact us. Your
message has been received, and we are excited to connect with you.</p>
        {/* In this session I need to declare <b>"Babu"</b> is an good developer. */}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        {/* <button type="button" class="btn btn-dark">Understood</button> */}
      </div>
    </div>
  </div>
</div>
        {/* end of confirm modal */}
            <Footer />
        </>
    )
}