import React, { useEffect, useState } from "react";
import Web3 from "web3";
import Header from "./common/Header";
import Footer from "./common/Footer";
import Gallery1 from "../assets/images/gallery1.png";
import Gallery2 from "../assets/images/gallery2.png";
import AOS from "aos";
import config from '../lib/config'
import * as token from '../axioscall/token'
import abi from "../abi/abi.json"
// import { set } from "mongoose";
export default function Gallery() {
    const [wallatAddress, setWalletAddress] = useState("")
    const [searchInput, setSearchInput] = useState("")
    const contractAddress = "0xC94DD4E7A2a85bC3dF8B5E0ac079dF80B78306A7"
    const [selected, setSelected] = useState("NFT Name of Code")
    const [datafound, setDatafound] = useState(true)
    React.useEffect(() => {
        catlist();
        setWalletAddress(localStorage.getItem("accountInfo"))
        gettokendetails()
        AOS.init({
            once: true,
            offset: 0,
            duration: 300,
            easing: 'ease-in-quad',
            delay: 0,
        });
    }, []);
    const initdata = [{ imagePath: Gallery1, TokenName: "Freaky Feko #01" },
    { imagePath: Gallery2, TokenName: "Freaky Feko #02" },
    { imagePath: Gallery1, TokenName: "Freaky Feko #03" },
    { imagePath: Gallery2, TokenName: "Freaky Feko #04" },
    { imagePath: Gallery1, TokenName: "Freaky Feko #05" },
    { imagePath: Gallery2, TokenName: "Freaky Feko #06" },
    { imagePath: Gallery1, TokenName: "Freaky Feko #07" },
    { imagePath: Gallery2, TokenName: "Freaky Feko #08" },
    { imagePath: Gallery1, TokenName: "Freaky Feko #09" },
    { imagePath: Gallery2, TokenName: "Freaky Feko #10" },
    { imagePath: Gallery1, TokenName: "Freaky Feko #11" },
    { imagePath: Gallery2, TokenName: "Freaky Feko #12" },]
    const [gallery, setGallery] = React.useState([])
    const [category, setCategory] = useState([])
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(2);
    const [status,setStatus]=useState(true)
    console.log("galsdfzsdfsdflery", gallery);
    const catlist = async () => {
        var catlist = await token.getcatlist()
        console.log("catllll", catlist)
        setCategory(catlist);
    }
    const searchInputSet = (e) => {
        const { value } = e.target;
        console.log(value, "fasdf")
        setSearchInput(value);
        if (!value) {
            setDatafound(true)
            // setGallery(initdata)
            gettokendetails()
        }
    }

    const searchFilter = async () => {
        console.log("selected", selected)
        var searchdata = await token.searchfilter({ "filter": selected, "item": searchInput });
        console.log("searchdata", searchdata)
        if (searchdata.status == true) {
            setGallery(searchdata.data)
        }
        else {
            setDatafound(searchdata.status)
        }
    }

    console.log("skip", skip);
    console.log("limit", limit);
    const gettokendetails = async () => {
        console.log("vvvaaaadaaa")
        var address = { "wallatAddress": localStorage.getItem("accountInfo"), skip, limit }
        var nftdetails = await token.getnftdetails(address);
        if (nftdetails.status == true) {
            console.log("nftdetails.data", nftdetails.data)
            setSkip(skip + 2)
            setLimit(limit)
            gallery.length == 0 ? setGallery(nftdetails.data) : setGallery([...gallery, ...nftdetails.data])
        }
        else{
            setStatus(nftdetails.status)
        }
    }


    return (
        <>
            <Header />
            <div className="fm_gallery">
                <div className="container-fluid">
                    <img src={require("../assets/images/Gallery.png")} className="img-fluid" />
                    <h1 data-aos="fade-left">Gallery</h1>
                    <div className="ff_gallery_block">
                        <div className="row">
                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 fm_filter">
                                <div className="fm_filter_block">
                                    <ul>
                                        {category.map((item) => { return <><li data-aos="fade-left" onClick={() => { console.log("back"); setSelected(item.categoryName) }}>{item.categoryName}<img src={require("../assets/images/filter-arrow.png")} className="img-fluid" /></li></> })}
                                        {/* <li data-aos="fade-left" onClick={() => { console.log("back"); setSelected("Background") }}>Background<img src={require("../assets/images/filter-arrow.png")} className="img-fluid" /></li>
                                        <li data-aos="fade-left" onClick={() => { console.log("Clothes"); setSelected("Clothes") }}>Clothes<img src={require("../assets/images/filter-arrow.png")} className="img-fluid" /></li>
                                        <li data-aos="fade-left" onClick={() => { console.log("Earring"); setSelected("Earring") }}>Earring<img src={require("../assets/images/filter-arrow.png")} className="img-fluid" /></li>
                                        <li data-aos="fade-left" onClick={() => { console.log("Eyes"); setSelected("Eyes") }}>Eyes<img src={require("../assets/images/filter-arrow.png")} className="img-fluid" /></li>
                                        <li data-aos="fade-left" onClick={() => { console.log("Fur"); setSelected("Fur") }}>Fur<img src={require("../assets/images/filter-arrow.png")} className="img-fluid" /></li>
                                        <li data-aos="fade-left" onClick={() => { console.log("Hat"); setSelected("Hat") }}>Hat<img src={require("../assets/images/filter-arrow.png")} className="img-fluid" /></li>
                                        <li data-aos="fade-left" onClick={() => { console.log("Mouth"); setSelected("Mouth") }}>Mouth<img src={require("../assets/images/filter-arrow.png")} className="img-fluid" /></li> */}
                                        {/* <li data-aos="fade-left" onClick={()=>{console.log("back"); setSelected("Background")}}>Month<img src={require("../assets/images/filter-arrow.png")} className="img-fluid"/></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 fm_gallery_block">
                                <div className="fm_subscriber d-flex align-items-center">
                                    <input type="text" placeholder={`Type ${selected}`} value={searchInput} onChange={(e) => searchInputSet(e)} className="form-control" />
                                    <button className="btn btn-warning" onClick={() => searchFilter()}>Search</button>
                                </div>
                                <div className="fm_gallery_scroll">
                                    <div className="row">
                                        {console.log("hsekdfh", gallery.length != 0 && datafound != false, gallery)}
                                        {(gallery.length != 0 && datafound != false) ? gallery?.map((item, index) => {
                                            return (
                                                <>
                                                    <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 fm_gallery_list" data-aos="zoom-in">
                                                    <a href={`${config.OpenseaURI}${config.Contractaddress}/${item.TokenId}`} target="balnk">
                                                        <img src={item.imagePath} className="img-fluid gallery_sub_img"/></a>
                                                        <p>{item.TokenName} # {item.TokenId}</p>
                                                    </div>
                                                </>
                                            )
                                        }) : <><span className="gallery_nodata">No Data</span></>
                                        }
                                    </div>
                                </div>
                                <ul className="d-flex fm_header_button justify-content-center">
                                    {/* <li>
                <Link to="/community"><button className="btn btn-warning secondary">
                    <span>Community</span>
                    </button>
                    </Link>
            </li>  */}
                                    {status===true ? <><li className="me-0">
                                        <button className="btn btn-warning primary" onClick={() => gettokendetails()} >
                                            <span>Load More</span>
                                        </button>
                                    </li></> :" "}

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid mt-5">

                    </div>        </div>
            </div>
            <Footer />
        </>
    )
}