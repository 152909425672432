import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
export default function Market(){
    return(
        <>
        <Header />
        <div className="fm_market">
            <div className="container-fluid">
            <img src={require("../assets/images/SHOP.png")} className="img-fluid" />
            {/* <h1>Shop / Design your own</h1> */}
            <div className="">
            <ul className="d-flex fm_header_button align-items-center justify-content-center">
                            <li className="me-3">
                                <button className="btn btn-warning primary">
                                    <span>View Collection</span>
                                </button>
                            </li>
                            <li className="me-3">
                                <button className="btn btn-warning primary">
                                    <span>Design Your Own</span>
                                </button>
                            </li>
                            </ul>
            </div>
            <div className="fm_comming_soon"  data-aos="fade-up">
                 <div className="fm_freaky_comming_soon_image">
                  <img src={require("../assets/images/comming-soon-line1.png")} className="img-fluid" data-aos="fade-left"/>
                  <h1>Coming Soon</h1>
                  <img src={require("../assets/images/comming-soon-line1.png")} className="img-fluid" data-aos="fade-right"/>
                </div>   
            </div>
            {/* <div className="fm_comming_description text-center">
                <p>A place to customize your art with FF digital assets and wearables.</p>
            </div> */}
            </div>
        </div>
        <Footer />
        </>
    )
}