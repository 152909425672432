import axios from 'axios'
import config from '../lib/config'


export const subscribersave=async(data)=>{
    try{
        var resp = await axios({
            "method":"post",
            "url":`${config.USERAPI}/addsubscriber`,
            "data": data
        })
    
        return resp.data  
    }
    catch(err){
       throw err
    }
}

export const getsociallink=async()=>{
    try{
        var resp = await axios({
            "method":"get",
            "url":`${config.USERAPI}/getsocial`
        })
    
        return resp.data  
    }
    catch(err){
       throw err
    }
}

export const getimages=async()=>{
    try{
        var resp = await axios({
            "method":"get",
            "url":`${config.USERAPI}/getimages`
        })
    
        return resp.data  
    }
    catch(err){
       throw err
    }
}
export const getarticle=async(data)=>{
    try{
        var resp = await axios({
            "method":"get",
            "url":`${config.USERAPI}/getarticle`,
            "params" : data
        })
        return resp.data  
    }
    catch(err){
       throw err
    }
}
export const getevents=async()=>{
    try{
        var resp = await axios({
                'method':'GET',
                'url':`${config.USERAPI}/getevent`,
        })
        console.log("member resp",resp.data)
        return resp.data;
    }
    catch(err){console.log("err in article list",err)}
}
  
export const getcms=async(data)=>{
    console.log("vaaeadfassdfa")
    try{
        var resp = await axios({
            "method":"get",
            "url":`${config.USERAPI}/getcms`,
            
        })
        return resp.data  
    }
    catch(err){
       throw err
    }
}
export const addcommunity=async(data)=>{
    try{
        var resp = await axios({
            "method":"post",
            "url":`${config.USERAPI}/addcommunity`,
            "data": data
        })
    
        return resp.data  
    }
    catch(err){
       throw err
    }
}
export const addmessage=async(data)=>{
    try{
        var resp = await axios({
            "method":"post",
            "url":`${config.USERAPI}/addmessage`,
            "data": data
        })
    
        return resp.data  
    }
    catch(err){
       throw err
    }
}

export const getfaq=async()=>{
    try{
        var resp = await axios({
            "method":"get",
            "url":`${config.USERAPI}/getfaqlist`,
        })
    
        return resp.data  
    }
    catch(err){
       throw err
    }
    
}