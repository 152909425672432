import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "./Home";
import Header from "./common/Header";
import FAQ from "./FAQ";
import Gallery from "./Gallery";
import Market from "./Market";
import FF from "./FF";
import TFF from "./TFF";
import ProvenanceRecord from "./ProvenanceRecord";
import CommunityMembership from "./CommunityMembership";

import Blog from "./Blog";
import Blogdetail from "./Blogdetail";

export default function Navigator(){
    return(
        <>
          <BrowserRouter basename="/" >
            <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route path="/blog" component={Blog} />
            <Route path="/blogdetail" component={Blogdetail} />
            <Route path="/faq" component={FAQ} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/market" component={Market} />
            <Route path="/ff" component={FF} />
            <Route path="/tff" component={TFF} />
            <Route path="/record" component={ProvenanceRecord} />
            <Route path="/community" component={CommunityMembership} />
            </Switch>
        </BrowserRouter>
        </>
    )
}