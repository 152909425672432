import React ,{useState,useRef, useEffect} from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { getimages } from "../axioscall/user";
import OwlCarousel from "react-owl-carousel-rtl";
import config from '../lib/config'
import { Link } from "react-router-dom";
export default function TFF(){
    const [filters,setFilters]=useState({"Introducing_TFS" : {},
    "Values" :{},
 })
    const options = {
        loop: true,
        margin: 20,
        nav: true,
        dots: false,
        smartSpeed: 1200,
        autoplay: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    }
    useEffect(()=>{
        getimage()
    },[])

    const getimage=async()=>{
        var resp=await getimages();
        console.log("imagedata",resp.data,resp);
        var Introducing_TFS=resp.data.filter(character => character.content === 'Introducing_TFS')
        var Values=resp.data.filter(character => character.content === 'Values')
       console.log("dddd",Introducing_TFS[0],Values)
       setFilters({"Introducing_TFS" : Introducing_TFS[0],
       "Values" :Values[0],
    })
       
    }

    return(
        <>
        <Header />
        <div className="fm_market fm_mymarket">
            <div className="container-fluid">
            <img src={require("../assets/images/tfs_foundation.png")} className="img-fluid" />
            <h1>TFS Foundation</h1>

            <div className="tfs_aboutus" data-aos="zoom-in" >
                    {/* <h1 className="tfs_ttl">About Us</h1> */}
                    <p className="tfs_detail_para">These pillars form the foundation of our work at TFS. By focusing on advocacy, education, support, collaboration, and research, we aim to make a meaningful impact on individuals' lives, their families, and the broader community. Together, we can create a society that values and prioritizes mental health, ensuring that everyone has the opportunity to thrive and lead fulfilling lives.</p>
                        </div>

                        <div className="row intro_tfs">
                            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                            <h1 className="tfs_ttl">Introducing TFS</h1>
                    <p className="tfs_detail_para">TFS is a newly established nonprofit organization that aims to make a positive impact on society by furthering the cause that Freaky Feko represents. Our foundation is dedicated to promoting, mental health, social awareness, fostering inclusivity, and empowering individuals to express themselves freely.</p>

                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                <img className="img-fluid" src={`${config.ImG}${filters.Introducing_TFS.content}/${filters.Introducing_TFS.img}`}/>
                            </div>
                        </div>

                        <div className="tfs_mission">
                        <h1 className="tfs_ttl">Mission</h1>
                    <p className="tfs_detail_para">The mission of TFS is to create a world where everyone feels confident, comfortable, and accepted as their true selves. We strive to bring people together, spark conversations, and build bridges that foster understanding and respect.</p>
                        </div>

                        <div className="tfs_objectives">
                        <h1 className="tfs_ttl">Objectives</h1>
                    {/* <p className="tfs_detail_para">Joining The Freaky Feko community means becoming part of an innovative movement that embraces the unconventional and encourages open-mindedness. Members will have access to exclusive content,</p> */}
                        </div>

                        <div className="row mt-5">
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-4">
                            <div className="tfs_obj_cards">
                                <h5>Advocacy and Support</h5>
                                <p>TFS works tirelessly to advocate for the rights of individuals to express themselves authentically, supporting diverse voices and perspectives. We aim to create a safe and inclusive environment for everyone to freely share their thoughts and ideas.</p>
                            </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12  mb-4">
                            <div className="tfs_obj_cards">
                                <h5>Community Outreach</h5>
                                <p> We actively engage with communities, organizing events, workshops, and initiatives that encourage dialogue, education, and empowerment. Our goal is to inspire individuals to embrace their uniqueness and stand up for what they believe in.</p>
                            </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12  mb-4">
                            <div className="tfs_obj_cards">
                                <h5>Artistic Expression</h5>
                                <p>Recognizing the power of art to ignite change, TFS supports artists and creators who use their talent to raise awareness and promote social causes. We provide grants, scholarships, and platforms for artists to showcase their work and make a meaningful impact.</p>
                            </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-4">
                            <div className="tfs_obj_cards">
                                <h5>Partnerships and Collaboration</h5>
                                <p>TFS collaborates with like-minded organizations, institutions, and individuals who share our vision. By forging partnerships, we amplify our collective efforts and maximize our reach to effect positive change on a broader scale. </p>
                            </div>
                            </div>
                        </div>

                        <div className="row intro_tfs">
                             <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                <img className="img-fluid" src={`${config.ImG}${filters?.Values?.content}/${filters?.Values?.img}`}/>
                            </div>
                            
                            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                            <h1 className="tfs_ttl">Values</h1>
                    <p className="tfs_detail_para margin_less">At TFS, we uphold the values of inclusivity, authenticity, creativity, and compassion. We believe in the transformative power of art, the strength of diverse voices, and the importance of fostering a sense of belonging in all communities.</p>
                    <p className="tfs_detail_para">
Together, through TFS, we can create a world that celebrates individuality, embraces diversity, and empowers everyone to express themselves fully. Join us in this journey of social change and together let's make a lasting impact. </p>

                            </div>
                           
                        </div>

                        <div className="fm_action_plan sub_carous" data-aos="fade-left">
                    <div className="container-fluid">
                        <h1>How TFS Works</h1>
                        <h1 className="tfs_porogramsub">Programs and Initiatives</h1>
                        <OwlCarousel  className='owl-theme tfs_owl' {...options}>
                            <div class='item tfs_owlitem'>
                                <div className="fm_plan tfs_fmplan">
                                    <img src={require("../assets/images/manbulb.png")} className=" ttf_seccarousel_img" data-aos="zoom-in" />
                                    <h5 className="tff_caryellow">Programs and Initiatives</h5>
                                    <p className="tfs_carsubtxt">TFS provides grants to emerging and established artists whose work aligns with our mission. These grants support the creation and exhibition of art that addresses social issues and promotes inclusivity through educational workshops & community outreach.
                                    </p>
                                    
                                </div>
                            </div>
                            <div class='item'>
                                <div className="fm_plan tfs_fmplan">
                                    <img src={require("../assets/images/collabration.png")} className=" ttf_seccarousel_img" data-aos="zoom-in" />
                                    <h5 className="tff_caryellow">Collaboration with Organizations</h5>
                                    <p className="tfs_carsubtxt">TFS collaborates with other nonprofit organizations, social enterprises, and advocacy groups to combine efforts and achieve common goals. These partnerships strengthen the impact of our initiatives and foster a collective approach to social change.
                                    </p>
                                </div>
                            </div>
                            <div class='item'>
                                <div className="fm_plan tfs_fmplan">
                                    <img src={require("../assets/images/raisehand.png")} className=" ttf_seccarousel_img" data-aos="zoom-in" />
                                    <h5 className="tff_caryellow">Volunteer Opportunities</h5>
                                    <p className="tfs_carsubtxt">TFS offers volunteer programs that allow individuals to contribute their time and skills to support our initiatives. Volunteers can participate in various activities such as event organization, art workshops, fundraising campaigns, and community outreach.
                                    </p>
                                </div>
                            </div>
                           
                            <div class='item'>
                                <div className="fm_plan tfs_fmplan">
                                    <img src={require("../assets/images/handmoney.png")} className=" ttf_seccarousel_img" data-aos="zoom-in" />
                                    <h5 className="tff_caryellow">Fundraising and Donations</h5>
                                    <p className="tfs_carsubtxt">As a nonprofit foundation, TFS relies on the generosity of donors and sponsors to fund our initiatives. We organize fundraising campaigns and events to generate financial support. Donations received are used to further our mission, support artists, and expand our outreach efforts. 
                                    </p>
                                </div>
                            </div>
                            <div class='item'>
                                <div className="fm_plan tfs_fmplan">
                                    <img src={require("../assets/images/gift-card 1.png")} className="ttf_seccarousel_img" data-aos="zoom-in" />
                                    <h5 className="tff_caryellow" >Online Platform</h5>
                                    <p className="tfs_carsubtxt">TFS maintains an online platform that serves as a hub for information, resources, and community engagement. The platform features news and updates about our activities, showcases artwork and creative projects, and provides opportunities for individuals to connect and get involved.
                                    </p>
                                </div>
                            </div>
                            <div class='item'>
                                <div className="fm_plan tfs_fmplan">
                                    <img src={require("../assets/images/stake1.png")} className="ttf_seccarousel_img" data-aos="zoom-in" />
                                    <h5 className="tff_caryellow">Impact Measurement and Reporting</h5>
                                    <p className="tfs_carsubtxt">TFS is committed to transparency and accountability. We regularly assess and measure the impact of our programs 
and initiatives, and provide comprehensive reports to our stakeholders, donors, and supporters. This ensures that the resources entrusted to us are utilized effectively and create tangible positive change.
                                    </p>
                                </div>
                            </div>
                           
                            
                        </OwlCarousel>
                    </div>
                    
                </div>

                
           
            </div>

            <div className="tfs_offerswhole">
            <div className="row h-100">
                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 tfs_offersleft">
                        <h1>What TFS Offers</h1>
                        <img className="tfs_absarrow" src={require('../assets/images/Group177.png')}/>
                        <img className="tfs_absdiamond" src={require('../assets/images/polygon.png')}/>

                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 tfs_offersright">
                        <div className="fm_slide fm_scroll tfs_scroll">
                            <h1><span className="pe-">1.</span> Mental Health Programs</h1>
                            <ol>
                                <li><p className="pe-3">A.</p> 
                                <p>TFS is dedicated to promoting mental health and well-being. We offer programs and resources that focus on mental health awareness, self-care practices, and support for individuals dealing with mental health challenges.</p></li>
                                <li><p className="pe-3">B.</p> 
                                <p>Our foundation collaborates with mental health professionals and organizations to provide access to counseling services, therapy programs, and educational materials related to mental health.</p></li>
                            </ol>

                            <h1>2. Advocacy and Awareness</h1>
                            <p>TFS actively advocates for mental health issues, striving to eliminate stigmas and raise awareness about the importance of mental well-being. We engage in public campaigns, organize events, and collaborate with likeminded organizations to amplify the voices of those affected by mental health challenges.</p>

                            <h1>3. Community Support:</h1>
                            <p>TFS creates a supportive community for individuals struggling with mental health concerns. We facilitate peer support groups, online forums, and safe spaces where people can share their experiences, find encouragement, and connect with others facing similar challenges.</p>

                            <h1>4. Resources and Education:</h1>
                            <p>TFS provides a wealth of educational resources on mental health topics, including articles, guides, and workshops. These resources aim to empower individuals with knowledge, coping strategies, and tools to prioritize their mental well-being.</p>

                            <h1>5. Partnerships with Mental Health Professionals:</h1>
                            <p>TFS collaborates with mental health professionals, therapists, and counselors to ensure that individuals in need have access to appropriate support. We work closely with these experts to develop and deliver targeted programs and services.</p>

                            <h1>6. Research and Innovation:</h1>
                            <p>TFS actively supports research and innovation in the field of mental health. We fund studies, projects, and initiatives aimed at advancing knowledge, improving treatment options, and finding new approaches to support individuals with mental health concerns.</p>

                            <h1>7. Breaking Barriers:</h1>
                            <p>TFS is committed to breaking down barriers to mental health care. We advocate for improved accessibility, affordability, and quality of mental health services, working towards a society where everyone can access the support, they need without facing unnecessary obstacles.</p>

                            <h1>8. Community Events and Workshops:</h1>
                            <p>TFS organizes community events and workshops focused on mental health. These gatherings bring people together, provide a platform for sharing experiences, and offer practical tools and techniques for managing mental well-being. Through these events, we foster a sense of connection and create a supportive environment.</p>

                            <h1>9. Collaborations with Schools and Institutions:</h1>
                            <p>TFS partners with schools, universities, and other institutions to implement mental health programs and initiatives. We work closely with educators and administrators to promote mental health awareness among students, provide resources for mental well-being, and support mental health education within the academic setting.</p>

                            <h1>10. Online Support Resources:</h1>
                            <p>TFS maintains an online platform where individuals can access a wide range of mental health resources. Our website offers articles, videos, downloadable materials, and links to helpful websites, creating a comprehensive hub of information and support. We strive to make valuable resources easily accessible to anyone seeking guidance or information about mental health.</p>




                        </div>
                    </div>
                </div>
                <div className="tff_event_whole">
                <div className="fm_big_event tff_event">
                    <div className="fm_content tff_fmcontent" data-aos="fade-left">
                        <h1>Get Involved</h1>
                        <p>If you are a brand, induvial or organization that empowers and align with our vision and goals, we would like to hear from you.</p>
                        <a href={`https://mail.google.com/mail/u/0/#inbox?compose=CllgCJZdkbsZchbcHfGTpQkDRLKRzMCgtbBgClhZmvzrwsMLsqppcbNMvVsmzDghcvTPWjMphLB`} target={"_blank"} className="tff_link">info@freakyfeko.com</a>
                       
                    </div>

                </div>
                </div>
            </div>

           
        </div>

        {/* mail modal */}

        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title text-center w-100 fs-5" id="staticBackdropLabel">Modal title</h1>
        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
        <i class="bi bi-x modal_cls_btn" data-bs-dismiss="modal" aria-label="Close"/>
      </div>
      <div class="modal-body">
        Mail has been sent
      </div>
      
    </div>
  </div>
</div>
{/* end of mail modal */}

        <Footer />
        </>
    )
}