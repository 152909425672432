import logo from './logo.svg';
import './App.css';
import Navigator from './component/Navigator';
import Home from './component/Home';
import "aos/dist/aos.css";
import abi from './abi/abi.json'
import Web3  from 'web3'
import config from './lib/config'
import * as tokenctrl from './axioscall/token'
import {ethers} from 'ethers'
import { useEffect ,useRef} from 'react';
function App() {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
}, [ ])
  function ctrlShiftKey(e, keyCode) {
    return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  }
document.onkeydown = (e) => {
  // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
  if (
    e.keyCode === 123 ||
    ctrlShiftKey(e, 'I') ||
    ctrlShiftKey(e, 'J') ||
    ctrlShiftKey(e, 'C') ||
    (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
  )
    return false;
};
  return (
    <div className="App">
      <div className="text-center">
        <Navigator />
      </div>
    </div>
  );
}

export default App;
