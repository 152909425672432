let key={}

// key.TokenURI="http://localhost:8080/v1/front";
// key.ContractAddress="0x019B618f910c6322e4d18B5F892626Ee49abEF2b"

// export default key;

// let key = {};
let IPFS_IMG = "https://freakyfeko.infura-ipfs.io/ipfs/";
var networkVersion = "";
var BNBProvider = "";
let Back_Url = "";
let ContractAddress="0x1ACD184Bd6FCF7c9105C93dfd4900bECC7a0e456";
let decimalValues = 1000000000000000000;
var FRONT_URL = "";
var ImG = "";
var OpenseaURI="";
var EnvName = "production";
var Front_market_Url = ''

if (EnvName === "local") {
  Back_Url = "http://localhost:8080/v1/front";
  Front_market_Url = "http://localhost:3000/fekomint";
  var image_url = "hhttp://localhost:3331/token";
  var openseauri="https://testnets.opensea.io/assets/mumbai/"
  ImG = "http://localhost:8080/";
  var tradeAddress = "0x71CD3e9f29bDA1475138A2D3e1cCd6916Ab9f4Bf";
  var singleAddress = "0x2616Cb548b40e076b72Ab24C21Bfc3356eE87383";
  var multipeAddress = "0x484496568071F25678C07B372eBd6Ac54Bc18aB2";
  var networkVersion = "97";
  var chainId = "0x61";
  BNBProvider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  var Back_Url_Token = "http://localhost:3331/v1";
}

if (EnvName === "stage") {
  Back_Url = "https://apistaging.theimpactcollective.com/front";
  Front_market_Url = "http://localhost:3000";
  var image_url = "https://apistaging.theimpactcollective.com/token";
  ImG = "https://apistaging.theimpactcollective.com";
  var tradeAddress = "0x16D9f2EA5Ab7c93Ef3aB2c7E983CfC12FB03b91c";
  var singleAddress = "0x26a40070a000f717A32d3e6AAe9A8f26552fB662";
  var multipeAddress = "0xae4d5a74432e6e0702bc61d7e537161ff5d740ac";
  var networkVersion = "97";
  var chainId = "0x61";
  BNBProvider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  var Back_Url_Token = "https://apistaging.theimpactcollective.com/v1";
}

if (EnvName === "demo") {
  Back_Url = "http://192.53.121.26:8902/v1/front";
  Front_market_Url = "http://nftdemo.bimaticz.com/fekomint";
  var image_url = "http://192.53.121.26:2525/token";
  ImG = "http://192.53.121.26:8902/";
  var openseauri="https://testnets.opensea.io/assets/mumbai/"
  var tradeAddress = "0x71CD3e9f29bDA1475138A2D3e1cCd6916Ab9f4Bf";
  var singleAddress = "0x2616Cb548b40e076b72Ab24C21Bfc3356eE87383";
  var multipeAddress = "0x484496568071F25678C07B372eBd6Ac54Bc18aB2";
  var networkVersion = "97";
  var chainId = "0x61";
  BNBProvider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  var Back_Url_Token = "http://192.53.121.26:8902/v1";
}

if (EnvName === "production") {
  Back_Url = "https://api.freakyfeko.com/v1/front";
  Front_market_Url = "https://www.freakyfeko.com";
  var image_url = "https://api.freakyfeko.com/token";
  ImG = "https://api.freakyfeko.com/";
  var openseauri="https://opensea.io/TheFreakyStudios/"
  var tradeAddress = "0x71CD3e9f29bDA1475138A2D3e1cCd6916Ab9f4Bf";
  var singleAddress = "0x2616Cb548b40e076b72Ab24C21Bfc3356eE87383";
  var multipeAddress = "0x484496568071F25678C07B372eBd6Ac54Bc18aB2";
  var networkVersion = "1";
  var chainId = "0x1";
  BNBProvider = "";
  var Back_Url_Token = "https://api.freakyfeko.com/v1";
}

key = {
  USERAPI: `${Back_Url}`,
  Back_Url: `${Back_Url_Token}`,
  chainId: chainId,
  Contractaddress : '0x1ACD184Bd6FCF7c9105C93dfd4900bECC7a0e456',
  OpenseaURI : openseauri,
  BNBProvider: BNBProvider,
  tradeAddress: tradeAddress,
  singleAddress: singleAddress,
  multipeAddress: multipeAddress,
  ImG: ImG,
  IPFS_IMG: IPFS_IMG,
  AdminAddress: "",
//   noimg: require("../assets/images/No_image.webp"),
  FRONT_URL: FRONT_URL,
  Front_market_Url:Front_market_Url,
  networkVersion:networkVersion
};

export default key;
